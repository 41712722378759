import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {LibMobileModule, MobileComponentsModule} from 'lib-mobile';
import {ComponentsModule} from '../../components/components.module';
import {HelpView} from './help.view';
import {FaqView} from './faq/faq.view';
import {FaqNodeView} from './faqNode/faqNode.view';

@NgModule({
    declarations: [
        HelpView,
        FaqView,
        FaqNodeView
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        IonicModule,
        LibMobileModule,
        ComponentsModule,
        MobileComponentsModule
    ]
})
export class HelpModule {

}
