import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    FrontMapMarkerHttpService,
    FrontPlugBusinessService,
    ReservationDuration,
    StationDto,
    UserAction,
    IziviaLoggerService
} from 'lib-front';
import {IziviaToastService, NewChargeContext, NewChargeStepType} from 'lib-mobile';
import {NavController, ViewDidEnter, ViewWillEnter} from '@ionic/angular';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'reservation-choice-view',
    templateUrl: './reservationChoice.view.html',
    styleUrls: ['./reservationChoice.view.scss'],
})
export class ReservationChoiceView implements ViewWillEnter, ViewDidEnter {
    newChargeContext: NewChargeContext;
    step: NewChargeStepType = NewChargeStepType.RESERVATION_CHOICE;
    station: StationDto | null;
    fetchingReservationDuration: boolean;
    reservationDurations: ReservationDuration[];
    reservationDurationSelected: ReservationDuration;
    isReservationAvailable: boolean = true;

    constructor(private readonly route: ActivatedRoute,
        private readonly mapMarkerService: FrontMapMarkerHttpService,
        private readonly navController: NavController) {
    }

    ionViewWillEnter() {
        this.generateGhost();
        this.fetchingReservationDuration = true;
        this.station = this.route.snapshot.data.station;

        // Could not resolved station (404, ...)
        if (this.station === null) {
            this.navController.navigateRoot('/app/new-charge/station-choice');
            return;
        }

        this.newChargeContext = this.route.snapshot.data.newChargeContext;
    }

    ionViewDidEnter() {
        if (this.newChargeContext.chargePoint.currentUserActions.includes(UserAction.RESERVE)) {
            // Merge doubt : which one is the good call station or chargepoint?
            // this.mapMarkerService.fetchReservationDurationOfChargeArea(this.station.chargeAreaRef, this.station.id)
            this.mapMarkerService.fetchReservationDurationOfChargeArea(this.station.chargeAreaRef, this.newChargeContext.chargePoint.id)
                .pipe(finalize(() => this.fetchingReservationDuration = false))
                .subscribe(reservationDurations => {
                    this.reservationDurations = reservationDurations;
                });
        } else {
            this.isReservationAvailable = false;
        }
    }

    goToMap(): void {
        this.navController.navigateRoot('/app/map');
    }


    goToNextSlide(): void {
        this.newChargeContext.reservation = this.reservationDurationSelected;

        this.navController.navigateForward(`/app/new-charge/station/${this.station.id}/media-choice`, {
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    goToPreviousSlide(): void {
        if (!!this.newChargeContext) {
            this.newChargeContext.reservation = undefined;

            this.navController.navigateBack(`/app/new-charge/station/${this.station.id}/charge-point-choice`, {
                queryParams: this.newChargeContext.toQueryParam()
            });
        }
    }

    canGoNext(): boolean {
        return ((this.newChargeContext?.chargePoint?.currentUserActions?.includes(UserAction.BUY_OTP)
            || this.newChargeContext?.chargePoint?.currentUserActions?.includes(UserAction.REMOTE_START))
            && (this.reservationDurationSelected !== undefined
                || !this.isReservationAvailable))
            || !!this.reservationDurationSelected;

    }

    private generateGhost() {
        this.reservationDurations = [{
            durationInMinutes: 0,
            price: {
                priceWithVAT: 0,
                currencyUnit: ''
            }
        }, {
            durationInMinutes: 0,
            price: {
                priceWithVAT: 0,
                currencyUnit: ''
            }
        }];
    }
}
