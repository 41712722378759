import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {EventDetailsView} from './eventsDetails/eventDetails.view';
import {EventDetailsStopView} from './eventsDetails/eventDetailsStop/eventDetailsStop.view';
import {MobileComponentsModule} from 'lib-mobile';
import {ComponentsModule} from '../../components/components.module';
import {LibFrontModule} from 'lib-front';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        MobileComponentsModule,
        LibFrontModule,
        RouterModule,
        ComponentsModule,
        IonicModule
    ],
    declarations: [
        EventDetailsView,
        EventDetailsStopView
    ]
})
export class EventsModule {
}
