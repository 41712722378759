import {TranslateService} from '@ngx-translate/core';
import {AVAILABLE_APP_LANGUAGES, DEFAULT_APP_LANGUAGE} from './i18n.config';
import {HttpClient} from '@angular/common/http';
import {FrontI18nResource, MultiTranslateHttpLoader} from 'lib-front';

export function getLocaleId(translateService: TranslateService): string {
    let localeId: string;

    if (AVAILABLE_APP_LANGUAGES.includes(translateService.getBrowserLang())) {
        localeId = translateService.getBrowserCultureLang() || translateService.getBrowserLang();
    } else {
        localeId = DEFAULT_APP_LANGUAGE;
    }

    return localeId;
}

export function createTranslateLoader(httpClient: HttpClient) {
    return new MultiTranslateHttpLoader(httpClient, [
        new FrontI18nResource('./assets/i18n/lib-front-', '.json', 0),
        new FrontI18nResource('./assets/i18n/lib-mobile-', '.json', 1),
        new FrontI18nResource('./assets/i18n/', '.json', 2),
        new FrontI18nResource('./assets/i18n/theme-labels-', '.json', 3)
    ]);
}
