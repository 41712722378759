import {Route} from '@angular/router';
import {StationResolver} from './station-resolver.service';
import {NewChargeSummaryView} from './newCharge/summary/summary.view';
import {MediaChoiceView} from './newCharge/mediaChoice/mediaChoice.view';
import {ChargePointChoiceView} from './newCharge/chargePointTypeChoice/chargePointChoice.view';
import {PlugTypeChoiceView} from './newCharge/plugTypeChoice/plugTypeChoice.view';
import {NewChargeContextResolver} from './newCharge/newChargeContext.resolver';
import {NewChargeContextGuard} from './newCharge/newChargeContext.guard';
import {StationChoiceView} from './newCharge/stationChoice/stationChoice.view';
import {ReservationChoiceView} from './newCharge/reservationChoice/reservationChoice.view';
import {PlugTypeChoiceGuard} from './newCharge/plugTypeChoice/plugTypeChoice.guard';
import {ChargePointTypeChoiceGuard} from './newCharge/chargePointTypeChoice/chargePointTypeChoice.guard';
import {RetryView} from './newCharge/retry/retry.view';

export const STATION_PATH_PARAM = 'stationId';

export const chargeAreasRoutes: Array<Route> = [
    {
        path: 'new-charge',
        resolve: {newChargeContext: NewChargeContextResolver},
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'station-choice', component: StationChoiceView
            },
            {
                path: `station/:${STATION_PATH_PARAM}`,
                resolve: {station: StationResolver},
                children: [
                    {path: '', redirectTo: 'plug-type-choice', pathMatch: 'full'},
                    {path: 'plug-type-choice', component: PlugTypeChoiceView},
                    {path: 'charge-point-choice', component: ChargePointChoiceView, canActivate: [NewChargeContextGuard, PlugTypeChoiceGuard]},
                    {path: 'reservation-choice', component: ReservationChoiceView, canActivate: [NewChargeContextGuard, ChargePointTypeChoiceGuard]},
                    {path: 'media-choice', component: MediaChoiceView, canActivate: [NewChargeContextGuard, ChargePointTypeChoiceGuard]},
                    {path: 'summary', component: NewChargeSummaryView, canActivate: [NewChargeContextGuard, ChargePointTypeChoiceGuard]},
                    {path: 'retry', component: RetryView, canActivate: [NewChargeContextGuard, ChargePointTypeChoiceGuard]}
                ]
            }
        ]
    }
];
