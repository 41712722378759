import {Component} from '@angular/core';
import {AvailabilityMacroStatus, PlugTypeChoice, PlugTypeDto, StationDto, UserAction} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {NavController, ViewWillEnter} from '@ionic/angular';
import {CONTEXT_KEY_PARAM, NewChargeContext, NewChargeContextService, NewChargeStepType} from 'lib-mobile';

@Component({
    selector: 'plug-type-choice-view',
    templateUrl: './plugTypeChoice.view.html',
    styleUrls: ['./plugTypeChoice.view.scss']
})
export class PlugTypeChoiceView implements ViewWillEnter {
    newChargeContext: NewChargeContext;
    step: NewChargeStepType = NewChargeStepType.PLUG_TYPE_CHOICE;
    station: StationDto | null;
    plugTypeDtos: PlugTypeDto[] = [];
    plugTypeChoice: PlugTypeChoice = new PlugTypeChoice();

    constructor(private readonly route: ActivatedRoute,
        private readonly newChargeContextService: NewChargeContextService,
        private readonly navController: NavController) {
    }

    ionViewWillEnter() {
        this.newChargeContext = this.route.snapshot.data.newChargeContext;
        this.station = this.route.snapshot.data.station;

        // Could not resolved station (404, ...)
        if (this.station === null) {
            this.navController.navigateRoot('/app/new-charge/station-choice');
            return;
        }

        this.plugTypeDtos = this.station.plugTypes;
        if (this.station.plugTypes && this.station.plugTypes.length === 1) {
            this.plugTypeChoice = new PlugTypeChoice();
            this.plugTypeChoice.plugType = this.station.plugTypes[0];
        }
    }

    goToNextSlide() {
        this.newChargeContext.plugTypeChoice = this.plugTypeChoice;

        // workaround when station resolver is not called because of the newChargeContext guard redirection to this plug type choice view
        // prevent newChargeContext.stationId being undefined in the following step
        this.newChargeContext.stationId = this.station.id;
        this.newChargeContext.otpSession.stationRef = this.station.id;
        this.newChargeContext.isReservable = this.station.currentUserActions.includes(UserAction.RESERVE);

        this.navController.navigateForward([`/app/new-charge/station/${this.station.id}/charge-point-choice`], {
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    goToPreviousSlide() {
        this.newChargeContext.stationId = undefined;
        this.newChargeContext.isReservable = undefined;
        this.newChargeContext.plugTypeChoice = undefined;
        this.navController.navigateBack([`/app/new-charge/station-choice`], {
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    canGoNext(): boolean {
        return !!this.plugTypeChoice && !!this.plugTypeChoice.plugType;
    }

    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }
}
