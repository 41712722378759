import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CONTEXT_KEY_PARAM, NewChargeContext, NewChargeContextService} from 'lib-mobile';

@Injectable()
export class NewChargeContextResolver implements Resolve<NewChargeContext> {
    constructor(private readonly newChargeContextService: NewChargeContextService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): NewChargeContext {
        let newChargeContext = this.newChargeContextService.getOrCreateNewChargeContext(route.queryParamMap.get(CONTEXT_KEY_PARAM));
        if (!newChargeContext.otpSession) {
            newChargeContext.otpSession = {};
        }
        return newChargeContext;
    }
}
