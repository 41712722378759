import {Component, ViewChild} from '@angular/core';
import {
    FILE_API_PATH,
    FrontIziviaTranslateService,
    FrontMapMarkerHttpService,
    FrontMediaHttpService,
    FrontOtpBusinessService,
    FrontOtpHttpService,
    FrontPlugBusinessService,
    FrontStorageService,
    IziviaError,
    IziviaLoggerService,
    Media,
    MediaFamilyType,
    MediaSummaryDto,
    OtpPriceDto,
    OtpPriceQueryParams,
    OTPSession, OtpSessionDto,
    OtpSessionRequest,
    OTPSessionStatus,
    PaymentOrderReceipt,
    PaymentOrderReceiptStatus,
    PaymentSource,
    PricingContextDto,
    StationDto,
    SupportContact
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {NavController, ViewDidEnter, ViewWillEnter, ViewWillLeave} from '@ionic/angular';
import {
    CurrentEnvironmentConfigService,
    IziviaToastService,
    IziviaUriOpenerService,
    ModalControllerService,
    NewChargeContext,
    NewChargeStepType,
    PaymentService, PAYNOW_USER_EMAIL,
    SupportChoiceComponent
} from 'lib-mobile';
import {catchError, filter, finalize, first, map, startWith, switchMap, take} from 'rxjs/operators';
import {forkJoin, interval, noop, Observable, of, Subscription, throwError, timer} from 'rxjs';
import {FrontEndService} from '../../../../services/frontEnd.service';
import {compact, find, isEmpty} from 'lodash-es';

@Component({
    selector: 'media-choice-view',
    templateUrl: './mediaChoice.view.html',
    styleUrls: ['./mediaChoice.view.scss']
})
export class MediaChoiceView implements ViewWillEnter, ViewDidEnter, ViewWillLeave {
    /**
     * @ViewChild must be used because our form reference (<code>#mediaChoiceForm</code>) is within a <code>*ngIf</code>
     * expression which makes it invisible from outside
     *
     * @see https://stackoverflow.com/a/44812573/2118909
     */
    newChargeContext: NewChargeContext;
    step: NewChargeStepType = NewChargeStepType.MEDIA_CHOICE;
    station: StationDto | null;
    mediaSummary: MediaSummaryDto;
    userEmail: string;
    otpPrice: OtpPriceDto = undefined; // Undefined means field was not initialized
    fetchingOtpPrice: boolean;
    payingOtp: boolean;
    startingCharge: boolean;
    hasPricingInfo: boolean;
    otpPriceTextError: string;
    orderingOtp: boolean;
    supportContacts: SupportContact[];
    termsAndConditionsAccepted: boolean = false;
    termsAndConditionsCode?: string;
    tabSelected: 'getMedia' | 'enterMedia' = 'getMedia';
    otpCode: string;
    otpError: boolean = false;
    otpValid: boolean = false;
    isCheckingOtp: boolean = false;
    otpErrorKey: string;
    isAutoValidatingOtp: boolean = false;
    isGoingToNextSlide: boolean = true;

    private thirdPartyId: string;
    private otpSession: OTPSession;
    private pollingSubscription: Subscription;
    private fetchOtpPriceSubscription: Subscription;
    private getMediaFormValid: boolean = false;

    constructor(private readonly route: ActivatedRoute,
        private readonly mediaHttpService: FrontMediaHttpService,
        private readonly navController: NavController,
        private readonly otpHttpService: FrontOtpHttpService,
        private readonly toastService: IziviaToastService,
        private readonly plugBusinessService: FrontPlugBusinessService,
        private readonly paymentService: PaymentService,
        private readonly modalControllerService: ModalControllerService,
        private readonly translateService: FrontIziviaTranslateService,
        private readonly otpBusinessService: FrontOtpBusinessService,
        private readonly mapMarkerService: FrontMapMarkerHttpService,
        private readonly frontEndInfoService: FrontEndService,
        private readonly loggerService: IziviaLoggerService,
        private readonly currentEnvironmentConfigService: CurrentEnvironmentConfigService,
        private readonly urlOpenerService: IziviaUriOpenerService,
        private readonly otpService: FrontOtpHttpService,
        private readonly storageService: FrontStorageService
    ) {
        this.frontEndInfoService.currentFrontEndInfo$.subscribe(frontEndInfo => {
            this.thirdPartyId = frontEndInfo?.id;
            this.termsAndConditionsCode = frontEndInfo?.otpTermsAndConditionsI18nCode;
            if (frontEndInfo?.config?.supportConfig?.supportContacts?.length) {
                this.supportContacts = frontEndInfo.config.supportConfig.supportContacts;
            }
        });
    }

    ionViewWillEnter() {
        this.otpSession = null;
        this.station = this.route.snapshot.data.station;

        // Force fetchingOtpPrice before to enter view. Fetching is launched on enter.
        // It allows to hide screen portion inherited from previous state of that view when we go back to it.
        this.fetchingOtpPrice = true;
        this.isGoingToNextSlide = false;

        // Could not resolved station (404, ...)
        if (this.station === null) {
            this.navController.navigateRoot('/app/new-charge/station-choice');
            return;
        }

        this.newChargeContext = this.route.snapshot.data.newChargeContext;
    }

    ionViewDidEnter() {
        this.hasPricingInfo = this.computeHasPricingInfo(this.station?.pricingContext);
        this.fetchOtpPriceSubscription = this.fetchOtpPrice()
            .subscribe();

        if (!!this.newChargeContext.otpSession.otpCode) {
            this.tabSelected = 'enterMedia';
            this.otpCode = this.newChargeContext.otpSession.otpCode;

            if (!this.userEmail) {
                this.storageService.get<string>(PAYNOW_USER_EMAIL).subscribe((email?: string) => {
                    this.userEmail = email;

                    if (!!this.userEmail && !!this.otpCode) {
                        this.isAutoValidatingOtp = true;
                        this.checkOtpIsValid();
                    }
                });
            }
        }
    }

    ionViewWillLeave() {
        this.pollingSubscription?.unsubscribe();
        this.fetchOtpPriceSubscription?.unsubscribe();
    }

    fetchOtpPrice(): Observable<void> {
        if (!this.newChargeContext.canFetchOtpPrice()) {
            this.otpPriceTextError = this.translateService.translate('charge.mediaChoice.error');
            this.loggerService.warning('Media choice: cannot fetch otp price. Invalid charge context', this.newChargeContext);
            return throwError(new IziviaError('Media choice: invalid charge context', true));
        }

        this.fetchingOtpPrice = true;
        this.otpPriceTextError = null;

        const otpPriceQueryParams: OtpPriceQueryParams = this.buildOtpPriceQueryParams(this.newChargeContext);
        return forkJoin({
            // Force a minimal duration to 1 sec. It avoid to display loading icon for a too small time.
            timer: timer(1000),
            otpPrice: this.otpHttpService.getOtpPriceDtoByStationAlias(this.newChargeContext.chargePoint.stationAlias, otpPriceQueryParams),
        }).pipe(
            map(({ otpPrice }) => otpPrice),
            map((otpPrice: OtpPriceDto) => {
                this.otpPrice = otpPrice || null; // Null to distinct undefined
                return noop();
            }),
            finalize(() => this.fetchingOtpPrice = false),
            catchError(error => {
                if (error && error.error && error.error.labelKey) {
                    this.otpPriceTextError = this.translateService
                        .translateWithDefault('server.' + error.error.labelKey,
                            'charge.mediaChoice.error');
                } else {
                    this.otpPriceTextError = this.translateService.translate('charge.mediaChoice.error');
                }
                this.loggerService.warning('Fetching otp price failed. Server error: ', error);
                // prevent from resuming the observable (will not enter next pipe operators)
                return throwError(error);
            })
        );
    }

    openSupportModal() {
        if (this.supportContacts) {
            this.modalControllerService.display({
                component: SupportChoiceComponent,
                componentProps: {
                    thirdPartyId: this.thirdPartyId,
                    stationAlias: this.newChargeContext.chargePoint.stationAlias
                }
            }).subscribe();
        }
    }

    order() {
        this.orderingOtp = true;
        const otpSessionRequest: OtpSessionRequest = this.buildOtpSessionRequest();

        this.otpHttpService.orderOtp(this.newChargeContext.chargePoint.stationAlias, otpSessionRequest)
            .pipe(finalize(() => this.orderingOtp = false))
            .subscribe((media: Media) => {
                this.mediaSummary = {
                    id: media._id,
                    expirationDate: media.expirationDate,
                    name: media.code,
                    visual: {
                        code: media.publicCode || media.code // media.code in case publicCode would be null
                    },
                    familyType: media.family.type || MediaFamilyType.DEFAULT
                };

                this.goToNextSlide();
            });
    }

    pay() {
        this.payingOtp = true;

        if (this.otpSession) {
            this.payOtpSession(this.otpSession);
        } else {
            const otpSessionRequest: OtpSessionRequest = this.buildOtpSessionRequest();
            this.otpHttpService.openOtpSession(otpSessionRequest).subscribe(
                (otpSession: OTPSession) => {
                    this.otpSession = otpSession;
                    this.payOtpSession(otpSession);
                },
                error => {
                    this.payingOtp = false;
                    this.toastService.showServerErrorOrFallback(error, 'otp.error.default');
                }
            );
        }
    }

    goToNextSlide() {
        this.newChargeContext.mediaSummary = this.mediaSummary;
        this.newChargeContext.otpSession.otpCode = this.otpSession.otpCode;

        this.isGoingToNextSlide = false;
        this.navController.navigateForward([`/app/new-charge/station/${this.station.id}/summary`], {
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    goToPreviousSlide() {
        this.newChargeContext.mediaSummary = undefined;
        // Unset charging infra information
        // These information will be set by previous slides according to other properties on newChargeContext
        this.newChargeContext.otpSession.stationRef = undefined;
        this.newChargeContext.otpSession.chargePointRef = undefined;
        this.newChargeContext.otpSession.plugRef = undefined;
        const nextStep: string = this.newChargeContext.isReservable ? 'reservation-choice' : 'charge-point-choice';
        this.navController.navigateBack([`/app/new-charge/station/${this.station.id}/${nextStep}`], {
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    canGoNext() {
        return !!this.mediaSummary;
    }

    canPay() {
        return !this.payingOtp
            && this.getMediaFormValid;
    }

    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    private buildOtpSessionRequest(): OtpSessionRequest {
        return {
            chargePointRef: this.newChargeContext.chargePoint.id,
            chargePointVisualId: this.newChargeContext.chargePoint.visualId,
            stationAlias: this.newChargeContext.chargePoint.stationAlias,
            orientation: this.plugBusinessService.plugOrientationToString(this.plugBusinessService.computePlugOrientation(
                this.newChargeContext.plugTypeChoice.plugType.userCableRequirement,
                this.newChargeContext.plugTypeChoice.hasPlugCable,
                true
            )),
            clientSource: PaymentSource.MOBILE,
            outletModel: this.newChargeContext.plugTypeChoice.plugType.name,
            userEmail: this.userEmail,
            reservationDuration: this.newChargeContext.hasReservation() ?
                this.newChargeContext.reservation.durationInMinutes * 60 : null
        };
    }

    private payOtpSession(otpSession: OTPSession) {
        switch (otpSession.status) {
            case OTPSessionStatus.OPEN:
            case OTPSessionStatus.WAIT_FOR:
            case OTPSessionStatus.WAIT_RESERVATION:
            case OTPSessionStatus.IGNORED_BY_USER:
                if (otpSession.price === 0 && otpSession.freeCharge === true) {
                    this.otpHttpService.closeOtpSession(otpSession._id).subscribe(
                        () => {
                            this.pollOtpSessionUntilEndedAndGoToNextSlide(otpSession._id);
                        },
                        () => {
                            this.payingOtp = false;
                            this.toastService.showError('otp.error.default');
                        }
                    );
                } else {
                    this.paymentService.payOtpSession(otpSession._id).subscribe((paymentOrderReceipt: PaymentOrderReceipt) => {
                        if (paymentOrderReceipt.status === PaymentOrderReceiptStatus.PAID
                                || paymentOrderReceipt.status === PaymentOrderReceiptStatus.ALREADY_PAID) {
                            this.pollOtpSessionUntilEndedAndGoToNextSlide(otpSession._id);
                        } else {
                            this.payingOtp = false;
                        }
                    },
                    error => {
                        if (error && error.error) {
                            switch (error.error['labelKey']) {
                                case 'error.otp.paymentOrderStatus.PAID':
                                    this.loggerService.debug('[payOtpSession] Previous payment order has been finally paid');
                                    this.pollOtpSessionUntilEndedAndGoToNextSlide(otpSession._id);
                                    return;
                                case 'error.otp.sessionExpired':
                                    this.loggerService.debug('[payOtpSession] Otp session has expired: open a new session');
                                    this.otpSession = undefined;
                                    this.pay();
                                    return;
                            }
                        }

                        this.payingOtp = false;
                        this.loggerService.error('Cannot open payment modal', error);
                        this.toastService.showError('otp.error.price.setup');
                    }
                    );
                }
                break;
            case OTPSessionStatus.TIMEOUT:
                this.payingOtp = false;
                this.toastService.showError('server.error.otp.sessionExpired');
                break;
            case OTPSessionStatus.ENDED:
                this.pollOtpSessionUntilEndedAndGoToNextSlide(otpSession._id);
                break;
            case OTPSessionStatus.ERROR:
                this.payingOtp = false;
                this.toastService.showError('otp.error.default');
                break;
        }
    }

    private pollOtpSessionUntilEndedAndGoToNextSlide(otpSessionId: string) {
        this.pollingSubscription = interval(5 * 1000) // Every 5 seconds
            .pipe(
                startWith(0),
                take(12), // Times out after one minute
                switchMap(() => this.otpHttpService.getOtpSession(otpSessionId)),
                filter(otpSession => !!otpSession),
                filter(otpSession => !this.otpBusinessService.canEvolve(otpSession)),
                first(),
                map(otpSession => {
                    if (otpSession.status === OTPSessionStatus.ENDED) {
                        return otpSession;
                    }

                    throw new Error(otpSession.status);
                }),
                finalize(() => this.payingOtp = false)
            )
            .subscribe(
                endedOtpSession => this.treatEndedOtpSession(endedOtpSession),
                error => {
                    this.loggerService.warning(`[pollOtpSessionUntilEndedAndGoToNextSlide] Otp session polling stopped.`,
                        otpSessionId, error);
                    this.toastService.showError('otp.error.default');
                    this.navController.navigateRoot('/events', {replaceUrl: true});
                }
            );
    }

    private treatEndedOtpSession(endedOtpSession) {
        this.otpSession = endedOtpSession;

        // Always persists locally the email used for otp session
        this.storageService
            .set(PAYNOW_USER_EMAIL, endedOtpSession.userEmail)
            .subscribe(() => {});

        this.mediaSummary = {
            id: this.otpSession.mediaRef,
            expirationDate: this.otpSession.otpExpirationDate,
            name: this.otpSession.otpCode,
            visual: {
                code: this.otpSession.otpCode
            },
            familyType: this.otpSession.otpType || MediaFamilyType.DEFAULT
        };

        this.goToNextSlide();
    }

    private computeHasPricingInfo(pricingContext: PricingContextDto): boolean {
        return pricingContext
            && pricingContext.pricingCodesBySubscriptionRef
            && Object.keys(pricingContext.pricingCodesBySubscriptionRef).length > 0
            && (Object.keys(pricingContext.pricingCodesBySubscriptionRef).length > 1
                || !!find(Object.values(pricingContext.pricingCodesBySubscriptionRef), (item) => !isEmpty(compact(item))));
    }

    isOtpClient() {
        return this.otpPrice && MediaFamilyType.OTP_CLIENT === this.otpPrice.otpType;
    }

    isOtpCb() {
        return this.otpPrice && MediaFamilyType.OTP_CB === this.otpPrice.otpType;
    }

    private buildOtpPriceQueryParams(chargeContext: NewChargeContext): OtpPriceQueryParams {
        return {
            chargePointRef: chargeContext.chargePoint.id,
            reservationDurationInMinutes: chargeContext.hasReservation() ? chargeContext.reservation.durationInMinutes : undefined
        };
    }

    openCgu() {
        this.currentEnvironmentConfigService.getCurrentDocumentBaseUrl()
            .subscribe(baseUrl => this.urlOpenerService.open(baseUrl + FILE_API_PATH + this.termsAndConditionsCode));
    }

    onMediaFormValidationChanged(isValid: boolean) {
        this.getMediaFormValid = isValid;
    }

    updateUserEmail(email: string) {
        this.userEmail = email;
    }

    onOtpCodeChanged(otpCode: string) {
        this.otpCode = otpCode;
        this.newChargeContext.otpSession.otpCode = otpCode;
    }

    goToSummary(otpSession: OtpSessionDto) {
        this.isGoingToNextSlide = true;

        this.otpSession = otpSession;
        this.otpHttpService.getActiveOtpMediaByCode(this.otpCode).subscribe((media: Media) => {
            this.mediaSummary = {
                id: media._id,
                expirationDate: otpSession.expirationDate,
                name: otpSession.otpCode,
                visual: {
                    code: otpSession.otpCode
                },
                familyType: media?.family?.type || MediaFamilyType.OTP_CB
            };

            this.goToNextSlide();
        });
    }

    checkOtpIsValid() {
        this.isCheckingOtp = true;
        this.otpError = false;
        this.otpValid = false;

        forkJoin({
            // Force a minimal duration to 1 sec. It avoid to display loading icon for a too small time.
            timer: timer(1000),
            otpSession: this.otpService.findUnexpiredOtpByCode(this.otpCode, this.station.id, this.userEmail),
        }).pipe(
            map(({ otpSession }) => otpSession)
        ).subscribe({
            next: (value: OtpSessionDto) => {
                this.otpError = !value;
                this.goToSummary(value);
            },
            error: (err) => {
                this.otpError = true;
                this.otpValid = false;
                this.isCheckingOtp = false;
                this.otpErrorKey = err.error?.labelKey != null
                    ? err.error.labelKey
                    : 'charge.mediaChoice.otpErrorMessage';
            },
            complete: () => {
                this.isCheckingOtp = false;
                this.otpValid = true;
                this.isAutoValidatingOtp = false;
            }
        });
    }
}
