import {Injectable} from '@angular/core';
import {FrontStationHttpService, IziviaError, IziviaLoggerService, StationDto, StationLazyCategory, UserAction} from 'lib-front';
import {IziviaToastService} from 'lib-mobile';
import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable()
export class StationSearcherService {

    constructor(private readonly toastService: IziviaToastService,
        private readonly loggerService: IziviaLoggerService,
        private readonly stationService: FrontStationHttpService) {
    }

    findByAlias(alias: string): Observable<StationDto> {
        return this.stationService.findByAlias(alias, [StationLazyCategory.CURRENT_USER_ACTIONS])
            .pipe(
                catchError(error => {
                    if (error.status === 404) {
                        this.toastService.showError('station.error.aliasNotFound');
                    } else {
                        this.toastService.showError('error.default');
                    }

                    return throwError(error);
                }),
                switchMap(station => {
                    if (!station.currentUserActions || !station.currentUserActions.includes(UserAction.BUY_OTP)) {
                        this.toastService.showWarning('station.stationUnavailable');
                        return throwError(new IziviaError(`${UserAction.BUY_OTP} is not available on station ${station.alias}`,
                            true));
                    }

                    return of(station);
                })
            );
    }
}
