import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {LibMobileModule} from 'lib-mobile';
import {ComponentsModule} from '../../components/components.module';

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        IonicModule,
        LibMobileModule,
        ComponentsModule
    ]
})
export class HomeModule {

}
