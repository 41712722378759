import {Component} from '@angular/core';
import {
    ChargePointDto,
    ChargePointLazyCategory,
    FrontPlugBusinessService,
    FrontStationHttpService,
    IziviaLoggerService,
    StationDto,
    UserAction
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {NavController, ViewDidEnter, ViewWillEnter, ViewWillLeave} from '@ionic/angular';
import {IziviaToastService, NewChargeContext, NewChargeStepType} from 'lib-mobile';
import {Subscription} from 'rxjs';

@Component({
    selector: 'charge-point-choice-view',
    templateUrl: './chargePointChoice.view.html',
    styleUrls: ['./chargePointChoice.view.scss']
})
export class ChargePointChoiceView implements ViewWillEnter, ViewDidEnter, ViewWillLeave {
    newChargeContext: NewChargeContext;
    step: NewChargeStepType = NewChargeStepType.CHARGE_AREA_CHOICE;
    chargePointDto: ChargePointDto;
    chargePoints: Array<ChargePointDto>;
    fetchingChargePoints: boolean;
    station: StationDto | null;

    private fetchChargePointsSubscription: Subscription;

    constructor(private readonly route: ActivatedRoute,
        private readonly stationService: FrontStationHttpService,
        private readonly plugBusinessService: FrontPlugBusinessService,
        private readonly toastService: IziviaToastService,
        private readonly loggerService: IziviaLoggerService,
        private readonly navController: NavController) {
    }

    ionViewWillEnter() {
        this.generateChargePointDtoGhost();
        this.fetchingChargePoints = true;
        this.station = this.route.snapshot.data.station;

        // Could not resolved station (404, ...)
        if (this.station === null) {
            this.navController.navigateRoot('/app/new-charge/station-choice');
            return;
        }

        this.newChargeContext = this.route.snapshot.data.newChargeContext;
    }

    ionViewDidEnter() {
        this.fetchChargePointsSubscription = this.stationService.findStationChargePoints(this.newChargeContext.stationId, {
            categories: [ChargePointLazyCategory.CURRENT_USER_ACTIONS],
            plugTypeName: this.newChargeContext.plugTypeChoice.plugType.name,
            plugOrientation: this.plugBusinessService.computePlugOrientation(
                this.newChargeContext.plugTypeChoice.plugType.userCableRequirement,
                this.newChargeContext.plugTypeChoice.hasPlugCable)
        }).subscribe(chargePoints => {
            // only propose CP with available userAction
            this.chargePoints = chargePoints.filter(chargePoint => chargePoint?.currentUserActions?.length > 0);
            if (chargePoints && chargePoints.length === 1) {
                this.chargePointDto = chargePoints[0];
            }
            this.fetchingChargePoints = false;
        });
    }

    ionViewWillLeave(): void {
        this.fetchChargePointsSubscription?.unsubscribe();
    }

    goToNextSlide() {
        this.newChargeContext.chargePoint = this.chargePointDto;
        this.newChargeContext.otpSession.chargePointRef = this.chargePointDto.id;
        const nextStep: string = this.newChargeContext.isReservable ? 'reservation-choice' : 'media-choice';
        this.stationService.getPlugByChargePointAndType(
            this.station.id,
            this.newChargeContext.otpSession.chargePointRef,
            this.newChargeContext.plugTypeChoice.plugType.name
        )
            .subscribe((plug) => {
                this.newChargeContext.otpSession.plugRef = plug._id;
                this.navController.navigateForward([`/app/new-charge/station/${this.station.id}/${nextStep}`], {
                    queryParams: this.newChargeContext.toQueryParam()
                });
            });
    }

    goToPreviousSlide() {
        if (!!this.newChargeContext) {
            this.newChargeContext.chargePoint = undefined;

            this.navController.navigateBack([`/app/new-charge/station/${this.station.id}/plug-type-choice`], {
                queryParams: this.newChargeContext.toQueryParam()
            });
        }
    }

    canGoNext(): boolean {
        return !!this.chargePointDto;
    }

    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    generateChargePointDtoGhost(): void {
        const chargePointDto: ChargePointDto = {
            id: '',
            stationVisualId: '',
            visualId: '',
            maxPowerInW: 0,
            stationAlias: '',
            userActions: [UserAction.BUY_OTP],
            userCableRequirement: undefined
        };

        this.chargePoints = [chargePointDto];
    }
}
