import {Component, Input} from '@angular/core';
import {OtpExpirationStatus, OtpSessionDto} from 'lib-front';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'otp-session-shortcut',
    templateUrl: './otpSessionShortcut.component.html',
    styleUrls: ['./otpSessionShortcut.component.scss']
})
export class OtpSessionShortcutComponent {
    @Input() otpSessionDto: OtpSessionDto;

    public OtpExpirationStatus = OtpExpirationStatus;

    constructor(private readonly navController: NavController) {
    }


    goToOtpEvent(otpSession: OtpSessionDto): void {
        if (!otpSession?.reservationRef) {
            this.navController.navigateRoot(`/events/details/otp/${otpSession?.id}/otp/${otpSession?.otpCode}`,
                {replaceUrl: true, animationDirection: 'forward'});
        } else {
            this.navController.navigateRoot(`/events/details/reservation/${otpSession?.reservationRef}/otp/${otpSession?.otpCode}`,
                {replaceUrl: true, animationDirection: 'forward'});
        }
    }

    goToChargeEvent(otpCode: string, chargeRecordRef: string): void {
        this.navController.navigateRoot(`/events/details/charge/${chargeRecordRef}/otp/${otpCode}`,
            {replaceUrl: true, animationDirection: 'forward'});
    }
}
