import {Component} from '@angular/core';
import {
    ChargeRecordStatus,
    FrontMediaHttpService,
    FrontOtpHttpService,
    IziviaLoggerService,
    OtpExpirationStatus,
    StationDto
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {NavController, ViewWillEnter} from '@ionic/angular';
import {IziviaToastService} from 'lib-mobile';
import {finalize} from 'rxjs/operators';
import {StationSearcherService} from '../../services/business/stationSearcher.service';

@Component({
    selector: 'otp-choice-view',
    templateUrl: './otpChoice.view.html',
    styleUrls: ['./otpChoice.view.scss']
})
export class OtpChoiceView implements ViewWillEnter {
    otpCode: string;
    isSearchingOtp: boolean;
    isSearchingStation: boolean;
    stationAlias: string;
    station: StationDto;

    constructor(private readonly route: ActivatedRoute,
        private readonly navController: NavController,
        private readonly toastService: IziviaToastService,
        private readonly loggerService: IziviaLoggerService,
        private readonly otpService: FrontOtpHttpService,
        private readonly mediaService: FrontMediaHttpService,
        private readonly stationSearcherService: StationSearcherService) {
    }

    ionViewWillEnter() {
    }

    goToNextSlide() {
        this.isSearchingOtp = true;
        this.otpService.findOtpSessionByStationAliasAndCode(this.stationAlias, this.otpCode).pipe(
            finalize(() => this.isSearchingOtp = false)
        ).subscribe(
            otp => {
                switch (otp.otpExpirationStatus) {
                    case OtpExpirationStatus.READY_TO_CHARGE:
                        this.navController.navigateRoot(`/events/details/otp/${otp.id}/otp/${this.otpCode}`,
                            {replaceUrl: true, animationDirection: 'forward'});
                        break;
                    case OtpExpirationStatus.CHARGING:
                        this.navController.navigateRoot(`/events/details/charge/${otp.chargeRecordRef}/otp/${this.otpCode}`,
                            {replaceUrl: true, animationDirection: 'forward'});
                        break;
                    case OtpExpirationStatus.EXPIRED:
                        if (otp.chargeRecordStatus === ChargeRecordStatus.VALORISED) {
                            this.toastService.showError('otp.charged');
                        } else {
                            this.toastService.showError('otp.expired');
                        }
                }
            },
            error => this.toastService.showError('otp.invalid'));
    }

    findStation(): void {
        this.isSearchingStation = true;

        this.stationSearcherService.findByAlias(this.stationAlias)
            .pipe(finalize(() => this.isSearchingStation = false))
            .subscribe(station => this.station = station);
    }


    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    isStationInputEnable(): boolean {
        return !this.isSearchingStation && !!this.stationAlias && this.stationAlias.length > 0;
    }

    canGoNext(): boolean {
        return !this.isSearchingOtp && !!this.otpCode && this.otpCode.length > 0;
    }
}
