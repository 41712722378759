import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    NewChargeContext
} from 'lib-mobile';

import {
    OtpPriceDto,
    StationDto
} from 'lib-front';
import {NgForm} from '@angular/forms';

@Component({
    selector: 'getMedia',
    templateUrl: './getMedia.component.html',
    styleUrls: ['./getMedia.component.scss']
})
export class GetMediaComponent implements AfterViewInit {
    @Input() userEmail: string;
    @Input() termsAndConditionsAccepted: boolean;
    @Input() termsAndConditionsCode: string;
    @Input() newChargeContext: NewChargeContext;
    @Input() otpPrice: OtpPriceDto;
    @Input() hasPricingInfo: boolean;
    @Input() station: StationDto | null;
    @Output() cguOpened: EventEmitter<void> = new EventEmitter<void>();
    @Output() isGetMediaFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() userEmailChanged: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('getMediaForm') getMediaForm: NgForm;

    ngAfterViewInit() {
        this.getMediaForm.valueChanges.subscribe(data => {
            this.isGetMediaFormValid.emit(this.getMediaForm.valid);
        });
    }

    openCgu() {
        this.cguOpened.next();
    }


    onUserEmailChange(mail: string) {
        this.userEmailChanged.next(mail);
    }
}
