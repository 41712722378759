import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {IziviaLoggerService} from 'lib-front';
import {CONTEXT_KEY_PARAM, NewChargeContextService} from 'lib-mobile';
import {STATION_PATH_PARAM} from '../chargeAreas.routes';

@Injectable()
export class NewChargeContextGuard implements CanActivate, CanActivateChild {
    constructor(private readonly router: Router,
        private readonly newChargeContextService: NewChargeContextService,
        private readonly loggerService: IziviaLoggerService) {

    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        return this.checkNewChargeContextExists(route, state);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        return this.checkNewChargeContextExists(route, state);
    }

    private checkNewChargeContextExists(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        const contextId = route.queryParamMap.get(CONTEXT_KEY_PARAM);
        const newChargeContext = this.newChargeContextService.getNewChargeContext(contextId);
        if (!!newChargeContext) {
            return true;
        } else {
            this.loggerService.debug(`No charge context were found when going to ${state.url}`);
            this.loggerService.warning(`Redirecting to step 1 of wizard (station choice).`);
            return this.router.createUrlTree([`/app/new-charge/station/${route.paramMap.get(STATION_PATH_PARAM)}/plug-type-choice`], {
                queryParamsHandling: 'preserve'
            });
        }
    }
}

