import {PaynowChargeHeaderComponent} from './paynowChargeHeader/paynowChargeHeader.component';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {MobileComponentsModule} from 'lib-mobile';
import {FrontPipesModule} from 'lib-front';
import {OtpSessionShortcutComponent} from './otpSessionShortcut/otpSessionShortcut.component';
import {TranslateModule} from '@ngx-translate/core';
import {SupportButtonComponent} from './supportButton/supportButton.component';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';

const components = [
    PaynowChargeHeaderComponent,
    OtpSessionShortcutComponent,
    SupportButtonComponent
];

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [
        IonicModule,
        CommonModule,
        FrontPipesModule,
        MobileComponentsModule,
        TranslateModule,
        RouterModule,
        FormsModule
    ]
})
export class ComponentsModule {
}
