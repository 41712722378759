import {Component, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {FaqFolderDetailDto, FaqHttpService, FaqNodeDto, FaqNodeType} from 'lib-front';
import {GhostComponent} from 'lib-mobile';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'izivia-faq-view',
    templateUrl: './faq.view.html',
    styleUrls: ['./faq.view.scss'],
})
export class FaqView extends GhostComponent implements OnInit {
    faqNodes: FaqNodeDto[] = [];
    rootNode: FaqFolderDetailDto;

    constructor(private readonly faqHttpService: FaqHttpService,
        private readonly navController: NavController) {
        super();
    }

    ngOnInit(): void {
        this.ghostMode = true;
        this.faqHttpService.findRootNode()
            .pipe(finalize(() => this.ghostMode = false))
            .subscribe(rootNode => {
                this.rootNode = rootNode;
                this.faqNodes = rootNode.children;
            });
    }

    goToFaqNode(faqNode: FaqNodeDto): void {
        this.navController.navigateForward(`help/faq/${faqNode.id}`);
    }

    treatFaqNodesSearched(faqNodesSearched: FaqNodeDto[]): void {
        this.faqNodes = faqNodesSearched ?  faqNodesSearched : this.rootNode?.children;
    }

    generateGhost(): void {
        this.faqNodes = new Array(3).fill({
            id: '',
            title: '',
            '@type': FaqNodeType.PAGE
        });
    }
}
