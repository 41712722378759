import {NgModule} from '@angular/core';
import {AppRootComponent} from './appRoot.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {homeRoutes} from './home/home.route';
import {HomeModule} from './home/home.module';
import {IonicModule} from '@ionic/angular';
import {MobileComponentsModule} from 'lib-mobile';
import {chargeAreasRoutes} from './chargeAreas/chargeAreas.routes';
import {ChargeAreasModule} from './chargeAreas/chargeAreas.module';
import {EventsModule} from './events/events.module';
import {eventsRoutes} from './events/events.routes';
import {OtpChoiceView} from './otpChoice/otpChoice.view';
import {FormsModule} from '@angular/forms';
import {ComponentsModule} from '../components/components.module';
import {HelpModule} from './help/help.module';
import {helpRoutes} from './help/help.route';

@NgModule({
    declarations: [
        AppRootComponent,
        OtpChoiceView
    ],
    imports: [
        RouterModule.forRoot([
            {path: '', redirectTo: '/home', pathMatch: 'full'},
            {path: 'home', children: homeRoutes, runGuardsAndResolvers: 'always'},
            {path: 'app', children: chargeAreasRoutes, runGuardsAndResolvers: 'always'},
            {path: 'events', children: eventsRoutes},
            {path: 'help', children: helpRoutes},
            {path: 'otp-choice', component: OtpChoiceView},
            {path: '**', redirectTo: '/home'}
        ],
        { useHash: true, paramsInheritanceStrategy: 'always' }),
        TranslateModule,
        CommonModule,
        HomeModule,
        HelpModule,
        ChargeAreasModule,
        EventsModule,
        IonicModule,
        MobileComponentsModule,
        FormsModule,
        ComponentsModule
    ],
    providers: [],
    exports: [AppRootComponent]
})
export class ViewsModule {
}
