import {Component} from '@angular/core';
import {
    ChargeAreaDetailsMarkerDto,
    ChargeAreaMarkerDto,
    FrontMapMarkerHttpService,
    IziviaLoggerService,
    MapMarkerDetailsLazyCategory,
    StationDto,
    UserAction
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {NavController, ViewWillEnter} from '@ionic/angular';
import {GhostComponent, IziviaToastService, NewChargeContext, NewChargeStepType} from 'lib-mobile';
import {StationSearcherService} from '../../../../services/business/stationSearcher.service';

@Component({
    selector: 'station-choice-view',
    templateUrl: './stationChoice.view.html',
    styleUrls: ['./stationChoice.view.scss']
})
export class StationChoiceView extends GhostComponent implements ViewWillEnter {
    newChargeContext: NewChargeContext;
    step: NewChargeStepType = NewChargeStepType.STATION_CHOICE;
    stationAlias: string;
    isSearchingStation: boolean;
    nearestChargeAreas: Array<SelectableChargeArea>;
    locationUnavailable: boolean;
    shouldDisplayAliasExplanation: boolean = false;

    constructor(private readonly route: ActivatedRoute,
        private readonly navController: NavController,
        private readonly toastService: IziviaToastService,
        private readonly loggerService: IziviaLoggerService,
        private readonly stationSearcherService: StationSearcherService,
        private readonly chargeAreaService: FrontMapMarkerHttpService) {
        super();
    }

    ionViewWillEnter() {
        this.ghostMode = true;
        this.newChargeContext = this.route.snapshot.data.newChargeContext;

        if (this.newChargeContext.isRetryOnOtherStation) {
            this.stationAlias = null;
            this.newChargeContext.isRetryOnOtherStation = false;
        }

        if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => this.chargeAreaService.searchNearestAroundMapMarkers({
                    referenceLat: position.coords.latitude,
                    referenceLng: position.coords.longitude,
                    distanceMaxInKm: 10,
                    limit: 3,
                    categories: [
                        MapMarkerDetailsLazyCategory.STATION
                    ],
                    userActions: [UserAction.BUY_OTP]
                }).subscribe(chargeAreas => {
                    this.nearestChargeAreas = chargeAreas;
                    this.ghostMode = false;
                }),
                () => {
                    this.handleUnavailableLocation();
                });
        } else {
            this.handleUnavailableLocation();
        }
    }

    private handleUnavailableLocation() {
        this.toastService.showWarning('station.error.location');
        this.locationUnavailable = true;
        this.nearestChargeAreas = [];
        this.ghostMode = false;
    }

    goToNextSlide() {
        this.isSearchingStation = true;

        this.stationSearcherService.findByAlias(this.stationAlias)
            .subscribe(
                station => {
                    this.newChargeContext.stationId = station.id;
                    this.newChargeContext.isReservable = station.currentUserActions.includes(UserAction.RESERVE);
                    this.newChargeContext.otpSession.stationRef = station.id;
                    this.navController.navigateForward([`/app/new-charge/station/${station.id}/plug-type-choice`], {
                        queryParams: this.newChargeContext.toQueryParam()
                    }).finally(() => this.isSearchingStation = false);
                },
                error => this.isSearchingStation = false
            );
    }

    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    canGoNext(): boolean {
        return !this.isSearchingStation && !!this.stationAlias && this.stationAlias.length > 0;
    }

    goToOtpChoice(): void {
        this.navController.navigateRoot(['/otp-choice'], {
            replaceUrl: true
        });

    }

    selectChargeArea(chargeArea: SelectableChargeArea): void {
        this.nearestChargeAreas
            .filter(value => value.selected && value.id !== chargeArea.id)
            .map(value => value.selected = false);
        chargeArea.selected = !chargeArea.selected;
    }

    selectStation(station: StationDto) {
        this.newChargeContext.stationId = station.id;
        this.newChargeContext.isReservable = station.userActions.includes(UserAction.RESERVE);
        this.navController.navigateForward([`/app/new-charge/station/${station.id}/plug-type-choice`], {
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    generateGhost(): void {
        this.nearestChargeAreas = [
            {'@type': 'chargeAreaDetails'},
            {'@type': 'chargeAreaDetails'},
            {'@type': 'chargeAreaDetails'}
        ];
    }

    toggleShouldDisplayAliasExplanation(): void {
        this.shouldDisplayAliasExplanation = !this.shouldDisplayAliasExplanation;
    }

    trackByChargeAreaId(index: number, chargeArea: ChargeAreaMarkerDto) {
        return chargeArea?.id ?? index;
    }

    trackByStationId(index: number, station: StationDto) {
        return station?.id ?? index;
    }
}

interface SelectableChargeArea extends ChargeAreaDetailsMarkerDto {
    selected?: boolean;
}
