import {Route} from '@angular/router';
import {EventDetailsView} from './eventsDetails/eventDetails.view';
import {EventDetailsStopView} from './eventsDetails/eventDetailsStop/eventDetailsStop.view';

export const eventsRoutes: Array<Route> = [
    {
        path: 'details/charge/stop', component: EventDetailsStopView,
    },
    {
        path: 'details/:type/:id/otp/:otpCode', component: EventDetailsView,
    }
];
