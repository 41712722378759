import {Component, Input} from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'support-button',
    templateUrl: './supportButton.component.html',
    styleUrls: ['./supportButton.component.scss']
})
export class SupportButtonComponent {
    @Input() color: string;


    constructor(private readonly navController: NavController) {
    }

    goToHelp(): void {
        this.navController.navigateForward('/help');
    }
}
