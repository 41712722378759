<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable defaultHref="/help"></izivia-back-button>
        <ion-title>{{'faq.title' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="faqNodeDetailDto">
    <faq-page *ngIf="isFaqPageDetail(faqNodeDetailDto)"
              [faqPage]="faqNodeDetailDto"
              [ghostMode]="ghostMode"></faq-page>

    <faq-folder *ngIf="isFaqFolderDetail(faqNodeDetailDto)"
                [faqFolder]="faqNodeDetailDto"
                (faqNodeSelected)="goToFaqNode($event)"></faq-folder>
</ion-content>

