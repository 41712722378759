<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToHome()"></izivia-back-button>

        <ion-title>{{'charge.otpChoice.title' | translate}}</ion-title>
        <ion-buttons slot="end">
            <support-button [color]="'white'"></support-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card class="menuButtonsView">
        <ion-card-content>
            <ion-card-header>
                <h4 class="title">{{'charge.stationChoice.fillAlias' | translate}}</h4>

                <div>
                    <div class="inputValidate">
                        <input class="inputBlock" type="text"
                               placeholder="{{'charge.stationChoice.aliasPlaceholder' | translate}}"
                               [(ngModel)]="stationAlias"/>
                        <button class="inputBlock validateButton" (click)="findStation()"
                                [disabled]="!isStationInputEnable()">
                            <ng-container *ngIf="!isSearchingStation; else spinner">
                                <ion-icon name="checkmark"></ion-icon>
                            </ng-container>
                        </button>
                    </div>

                    <izivia-help-text>{{'charge.stationChoice.aliasExplanation' | translate}}</izivia-help-text>
                </div>

                <ng-container *ngIf="station">

                    <h4 class="title">{{'charge.otpChoice.fillCode' | translate}}</h4>

                    <div class="inputValidate">
                        <input class="inputBlock" type="text" [(ngModel)]="otpCode"/>
                        <button class="inputBlock validateButton" (click)="goToNextSlide()"
                                [disabled]="!canGoNext()">
                            <ng-container *ngIf="!isSearchingOtp; else spinner">
                                <ion-icon name="checkmark"></ion-icon>
                            </ng-container>
                        </button>
                    </div>
                </ng-container>

            </ion-card-header>
        </ion-card-content>
    </ion-card>
</ion-content>



<ion-footer class="ion-no-border ion-text-center">
    <ion-toolbar>
        <div class="text-center">
            <ion-button slot="start" color="secondary" expand="block" fill="outline" (click)="goToHome()" class="previous">
                {{'common.cancel' | translate}}
            </ion-button>
        </div>
    </ion-toolbar>
</ion-footer>


<ng-template #spinner>
    <ion-spinner color="light"></ion-spinner>
</ng-template>
