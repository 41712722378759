<ng-container [ngSwitch]="otpSessionDto.otpExpirationStatus">
        <ion-item *ngSwitchCase="OtpExpirationStatus.READY_TO_CHARGE"
                  (click)="goToOtpEvent(otpSessionDto)">
            <ion-col size="shrink"><i [ngClass]="{'icon-password' : !otpSessionDto?.reservationRef,
            'icon-calendar' : otpSessionDto?.reservationRef}"></i></ion-col>
            <ion-col>
                <ion-row class="xlarge-ghost-size"></ion-row>
                <ion-row class="xlarge-ghost-size"></ion-row>
                <ion-row class="homeEvent-inProgress-text ghost-hidden">{{otpSessionDto.infraChargeDto.chargeArea.name}}
                    -
                    {{'infra.station' | translate: {stationVisualId: otpSessionDto.infraChargeDto.station.name} }}</ion-row>
                <ion-row class="homeEvent-inProgress-subtext ghost-hidden">
                    <ng-container *ngIf="!otpSessionDto.reservationRef else reservationText">
                        {{'otp.shortcut.otpValidUntil' | translate:{
                        expirationDate:
                            otpSessionDto.expirationDate | date:'medium'
                    } }}
                    </ng-container>
                </ion-row>
                <ng-template #reservationText>
                    {{'otp.shortcut.reservationValidUntil' | translate:{
                    expirationDate:
                        otpSessionDto.expirationDate | date:'medium'
                } }}
                </ng-template>
            </ion-col>
            <ion-col size="shrink ghost-hidden">
                <ion-icon class="homeEvent-inProgress-iconDirection" name="arrow-forward"></ion-icon>
            </ion-col>
        </ion-item>

        <ion-item *ngSwitchCase="OtpExpirationStatus.CHARGING"
                  (click)="goToChargeEvent(otpSessionDto.otpCode, otpSessionDto.chargeRecordRef)">
            <ion-col size="shrink"><i class="icon-charge"></i></ion-col>
            <ion-col>
                <ion-row class="xlarge-ghost-size"></ion-row>
                <ion-row class="xlarge-ghost-size"></ion-row>
                <ion-row class="homeEvent-inProgress-text ghost-hidden">{{otpSessionDto.infraChargeDto.chargeArea.name}} -
                    {{'infra.station' | translate: {stationVisualId: otpSessionDto.infraChargeDto.station.name} }}</ion-row>
                <ion-row class="homeEvent-inProgress-subtext ghost-hidden">{{'otp.shortcut.charging' | translate}}</ion-row>
            </ion-col>
            <ion-col size="shrink ghost-hidden">
                <ion-icon class="homeEvent-inProgress-iconDirection" name="arrow-forward"></ion-icon>
            </ion-col>
        </ion-item>

        <ion-item *ngSwitchDefault>
            <ion-col>
                <ion-row class="xlarge-ghost-size"></ion-row>
                <ion-row class="xlarge-ghost-size"></ion-row>
            </ion-col>
        </ion-item>
</ng-container>
