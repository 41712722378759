import {Injectable} from '@angular/core';
import {ajax} from 'rxjs/ajax';
import {tap} from 'rxjs/operators';
import {IziviaServerEnvironment} from 'lib-front';
import {PaynowConfig} from '../domain/paynowConfig';

@Injectable()
export class ConfigAppService {
    config: PaynowConfig;

    constructor() {
    }

    init(): Promise<any> {
        return ajax.getJSON<PaynowConfig>('/api/v2/front/paynow/config')
            .pipe(tap(paynowConfig => this.config = paynowConfig))
            .toPromise();
    }

    getIziviaEnvs() {
        return [this.getIziviaDefaultEnv()];
    }

    getIziviaDefaultEnv() {
        return {
            key: 'default',
            name: 'Default',
            url: '',
            paymentDefaultUrl: this.config.paymentDefaultUrl,
            paymentFailoverUrl: this.config.paymentFailoverUrl
        };
    }

    getSentryEnvironment(): IziviaServerEnvironment {
        return this.config.sentryEnvironment;
    }

    getSentryToken(): string {
        return this.config.sentryToken;
    }

    getSentryProject(): string {
        return this.config.sentryProject;
    }

    getMatomoSiteId(): string {
        return this.config.matomoSiteId;
    }

    getMatomoTrackerUrl(): string {
        return this.config.matomoTrackerUrl;
    }
}
