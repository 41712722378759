<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goBack()"></izivia-back-button>

        <ion-title>{{remoteStart ? ('remote.start.title' | translate) : ('charge.summary.title' | translate)}}</ion-title>
        <ion-buttons slot="end">
            <support-button [color]="'white'"></support-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ng-container *ngIf="!remoteStart; else remoteStartContainer">
    <ion-content>
        <ion-card class="padding-16">
            <paynow-charge-header [newChargeContext]="newChargeContext"
                                  [station]="station"
                                  [step]="step"></paynow-charge-header>
        </ion-card>

        <new-charge-summary [pricingContextObs]="pricingContextObs"
                            [defaultPricingContext]="station.pricingContext"
                            [newChargeContext]="newChargeContext"
                            *ngIf="station && newChargeContext && pricingContextObs"></new-charge-summary>
    </ion-content>

    <ion-footer class="ion-no-border ion-text-center">
        <ion-toolbar>
            <ion-button slot="start" color="secondary" expand="block" class="previous"
                        (click)="goToPreviousSlide()">
                {{'common.previous' | translate}}
            </ion-button>
            <ion-button slot="end" color="secondary" expand="block" class="start"
                        (click)="showRemoteStart()"
                        *ngIf="canRemoteStart && !newChargeContext?.hasReservation() else goHome">
                {{'charge.summary.start' | translate}}
            </ion-button>
            <ng-template #goHome>
                <ion-button [slot]="!newChargeContext?.isOTP() ? 'end' : ''" color="secondary" expand="block"
                            class="close" (click)="goToHome()">
                    <ng-container *ngIf="newChargeContext?.hasReservation() else closeText">
                        {{'charge.summary.reservation' | translate}}
                    </ng-container>
                    <ng-template #closeText>{{'common.close' | translate}}</ng-template>
                </ion-button>
            </ng-template>
        </ion-toolbar>
    </ion-footer>
</ng-container>

<ng-template #remoteStartContainer>
    <ion-content>
        <ion-card>
            <ion-card-content class="bottomMenuView">
                <remote [hidden]="!remoteRequest"
                        [action]="Action.START"
                        [request]="remoteRequest"
                        (remoteResponse)="handleRemoteResponse($event)"></remote>
            </ion-card-content>
        </ion-card>
    </ion-content>
</ng-template>
