import {Component, OnInit} from '@angular/core';
import {
    CurrentEnvironmentConfigService,
    IziviaUriOpenerService,
    ModalControllerService,
    SupportChoiceComponent
} from 'lib-mobile';
import {FrontEndService} from '../../services/frontEnd.service';
import {filter, map, tap} from 'rxjs/operators';
import {FILE_API_PATH, FrontApiInfoHttpService, SupportContact} from 'lib-front';
import {NavController} from '@ionic/angular';
import * as moment from 'moment';

@Component({
    selector: 'help',
    templateUrl: './help.view.html',
    styleUrls: ['./help.view.scss']
})
export class HelpView implements OnInit {
    supportContacts: SupportContact[];
    isFaqAvailable: boolean;
    frontEndTermsAndConditionsI18nCode: string;
    currentYear: number;
    public version: string;
    private thirdPartyId: string;


    constructor(
        private readonly modalControllerService: ModalControllerService,
        private readonly navController: NavController,
        private readonly frontEndInfoService: FrontEndService,
        private readonly frontEndService: FrontEndService,
        private readonly currentEnvironmentConfigService: CurrentEnvironmentConfigService,
        private readonly urlOpenerService: IziviaUriOpenerService,
        private readonly apiInfoHttpService: FrontApiInfoHttpService) {
    }


    open(i18nCode: string): void {
        this.currentEnvironmentConfigService.getCurrentDocumentBaseUrl()
            .subscribe(baseUrl => this.urlOpenerService.open(baseUrl + FILE_API_PATH + i18nCode));
    }

    ngOnInit(): void {
        this.currentYear = moment().year();
        this.apiInfoHttpService.version()
            .subscribe(version => this.version = version);

        this.frontEndInfoService.currentFrontEndInfo$
            .pipe(
                tap(frontEndInfo => this.thirdPartyId = frontEndInfo?.id),
                filter(frontEndInfo => !!frontEndInfo?.config?.supportConfig?.supportContacts?.length),
                tap(frontEndInfo => this.isFaqAvailable = frontEndInfo?.config?.availableFaq),
                tap(frontEndInfo => this.frontEndTermsAndConditionsI18nCode = frontEndInfo?.frontEndTermsAndConditionsI18nCode),
                map(frontEndInfo => frontEndInfo.config.supportConfig.supportContacts))
            .subscribe(supportContacts => this.supportContacts = supportContacts);
    }

    openSupportChoice(): void {
        this.modalControllerService.display({
            component: SupportChoiceComponent,
            componentProps: {
                thirdPartyId: this.thirdPartyId
            }
        }).subscribe();
    }

    goToHome() {
        this.navController.navigateRoot([`/home`], {replaceUrl: true}).then();
    }
}
