import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CurrentEnvironmentConfigService, IziviaMobileEnvironmentConfig, IziviaPlatformService} from 'lib-mobile';
import {FrontApiInfoHttpService, HOST_THIRD_PARTY_ID, HttpCodesUtil, IziviaLoggerService, IziviaSentryService, MatomoService} from 'lib-front';
import {ConfigAppService} from '../services/configApp.service';
import {switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FrontEndService} from '../services/frontEnd.service';
import {AVAILABLE_APP_LANGUAGES, DEFAULT_APP_LANGUAGE} from '../services/i18n/i18n.config';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './appRoot.component.html',
    styleUrls: ['./appRoot.component.scss']
})
export class AppRootComponent implements OnInit {

    constructor(private readonly translateService: TranslateService,
        private readonly platformService: IziviaPlatformService,
        private readonly apiInfoHttpService: FrontApiInfoHttpService,
        private readonly configAppService: ConfigAppService,
        private readonly currentEnvironmentConfigService: CurrentEnvironmentConfigService,
        private readonly sentryService: IziviaSentryService,
        private readonly loggerService: IziviaLoggerService,
        private readonly frontEndService: FrontEndService,
        private readonly matomoService: MatomoService,
        @Inject(HOST_THIRD_PARTY_ID) private thirdPartyId: string) {
    }

    ngOnInit(): void {
        this.initializeLanguage();

        this.platformService.ready()
            .pipe(
                switchMap(() => this.loadEnvironment()),
                switchMap(() => this.apiInfoHttpService.version()),
                tap(apiVersion => {
                    if (environment.production) {
                        this.sentryService.startSentry(apiVersion, this.configAppService.config);
                    } else {
                        this.loggerService.debug('Sentry is disabled in development mode');
                    }
                }),
                switchMap(() => this.frontEndService.loadFrontEndInfo()),
            )
            .subscribe(
                () => this.onAppReady(),
                error => this.handleInitializationError(error)
            );
    }

    private initializeLanguage(): void {
        this.translateService.setDefaultLang(DEFAULT_APP_LANGUAGE);

        const browserLangCode: string = this.translateService.getBrowserLang();

        // performs an additional check in order to prevent a HTTP 404 error (e.g. when requesting 'en.json')
        if (AVAILABLE_APP_LANGUAGES.includes(browserLangCode)) {
            this.translateService.use(browserLangCode);
        } else {
            this.translateService.use(DEFAULT_APP_LANGUAGE);
        }
    }

    private handleInitializationError(error): void {
        if (HttpCodesUtil.isServerUnderMaintenance(error)) {
            this.loggerService.debug('[handleInitializationError] Server under maintenance', error);
        } else {
            this.loggerService.error('[handleInitializationError] Unexpected error while initializing app', error);
        }
    }

    private loadEnvironment(): Observable<IziviaMobileEnvironmentConfig> {
        return this.currentEnvironmentConfigService.loadCurrentEnvironmentConfig$()
            .pipe(
                tap(config => this.loggerService.info('--- Environment loaded ---', config))
            );
    }

    private onAppReady(): void {
        this.initAnalytics();
    }

    private initAnalytics(): void {
        const siteId = this.configAppService.getMatomoSiteId();
        const trackerUrl = this.configAppService.getMatomoTrackerUrl();

        if (siteId && trackerUrl) {
            this.matomoService.startMatomo({
                siteId,
                trackerUrl
            });
        }
    }
}
