import {Component} from '@angular/core';
import {NavController, SelectCustomEvent, ViewDidEnter} from '@ionic/angular';
import {GhostComponent, NewChargeContextService} from 'lib-mobile';
import {AVAILABLE_APP_LANGUAGES} from '../../services/i18n/i18n.config';
import {TranslateService} from '@ngx-translate/core';
import {FrontOtpHttpService, OtpSessionDto} from 'lib-front';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends GhostComponent implements ViewDidEnter {
    languages: Array<string> = AVAILABLE_APP_LANGUAGES;
    currentOtpSessions: Array<OtpSessionDto>;

    constructor(private readonly navController: NavController,
        public readonly translateService: TranslateService,
        private readonly otpService: FrontOtpHttpService,
        private readonly newChargeContextService: NewChargeContextService) {
        super();
    }

    ionViewDidEnter() {
        this.ghostMode = true;
        this.otpService.findUnexpiredOtp().subscribe(otpSessions => {
            this.currentOtpSessions = otpSessions;
            this.ghostMode = false;
        });
    }

    goToBuyCode(): void {
        this.newChargeContextService.startNewCharge('/app/new-charge/station-choice')
            .subscribe();
    }

    goToStopCharge(): void {
        this.navController.navigateRoot(['/otp-choice'], {
            replaceUrl: true
        });
    }

    changeLanguage(event: Event) {
        this.translateService.use((event as SelectCustomEvent<string>).detail.value);
    }

    generateGhost(): void {
        this.currentOtpSessions = [
            {
                currencyUnit: '',
                expirationDate: '',
                id: '',
                otpCode: '',
                otpPrice: 0,
                pricingContext: undefined,
                remoteStart: false,
                infraChargeDto: {
                    chargeArea: {
                        id: undefined,
                        geolocation: undefined,
                        name: undefined
                    },
                    chargePoint: undefined,
                    plug: undefined,
                    station: {
                        id: undefined,
                        name: undefined
                    }
                }
            },
            {
                currencyUnit: '',
                expirationDate: '',
                id: '',
                otpCode: '',
                otpPrice: 0,
                pricingContext: undefined,
                remoteStart: false,
                infraChargeDto: {
                    chargeArea: {
                        id: undefined,
                        geolocation: undefined,
                        name: undefined
                    },
                    chargePoint: undefined,
                    plug: undefined,
                    station: {
                        id: undefined,
                        name: undefined
                    }
                }
            },
            {
                currencyUnit: '',
                expirationDate: '',
                id: '',
                otpCode: '',
                otpPrice: 0,
                pricingContext: undefined,
                remoteStart: false,
                infraChargeDto: {
                    chargeArea: {
                        id: undefined,
                        geolocation: undefined,
                        name: undefined
                    },
                    chargePoint: undefined,
                    plug: undefined,
                    station: {
                        id: undefined,
                        name: undefined
                    }
                }
            }
        ];
    }

    trackByOtpSessionId(index: number, otpSession: OtpSessionDto) {
        return otpSession?.id ?? index;
    }
}
