import {Component, NgZone} from '@angular/core';
import {NavController} from '@ionic/angular';

import {
    AlertControllerService,
} from 'lib-mobile';
import {FrontIziviaTranslateService} from 'lib-front';

@Component({
    selector: 'izivia-event-details-stop',
    templateUrl: './eventDetailsStop.view.html',
    styleUrls: ['./eventDetailsStop.view.scss'],
})
export class EventDetailsStopView {

    constructor(private readonly alertControllerService: AlertControllerService,
        private readonly navController: NavController,
        private readonly translateService: FrontIziviaTranslateService) {
    }

    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    presentRemoteStop() {
        this.alertControllerService.display({
            header: this.translateService.translate('remote.stop.modal.title'),
            message: this.translateService.translate('remote.stop.modal.message'),
            buttons: [
                {
                    text:  this.translateService.translate('remote.stop.modal.home'),
                    handler: () => this.goToHome()
                }
            ]
        }).subscribe();
    }
}
