<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToHome()"></izivia-back-button>

        <ion-title>{{'charge.stationChoice.title' | translate}}</ion-title>
        <ion-buttons slot="end">
            <support-button [color]="'white'"></support-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card>
        <ion-card-header>
            <paynow-charge-header [newChargeContext]="newChargeContext"
                                  [step]="step"></paynow-charge-header>
        </ion-card-header>
    </ion-card>

    <ion-card class="tunnelView padding-16">
        <ion-card-content>
            <h4 class="title">{{'charge.stationChoice.fillAlias' | translate}}</h4>

            <div>
                <div class="inputValidate">
                    <input class="inputBlock" type="text"
                           placeholder="{{'charge.stationChoice.aliasPlaceholder' | translate}}"
                           [(ngModel)]="stationAlias"/>
                    <button class="inputBlock validateButton" (click)="goToNextSlide()"
                            [disabled]="!canGoNext()">
                        <ng-container *ngIf="!isSearchingStation; else spinner">
                            <ion-icon name="checkmark"></ion-icon>
                        </ng-container>
                    </button>
                </div>

                <izivia-help-text>{{'charge.stationChoice.aliasExplanation' | translate}}</izivia-help-text>
            </div>

            <h4 class="textOr">{{'station.or' | translate}}</h4>

            <div>
                <h4 class="title stationTitle">{{'station.nearestStations' | translate}}</h4>
            </div>
            <ion-list [class.ghost]="ghostMode" *ngIf="nearestChargeAreas?.length > 0 else noNearestChargeArea">
                <ion-item class="selectBorn" *ngFor="let chargeArea of nearestChargeAreas; trackBy: trackByChargeAreaId"
                          [class.selected]="chargeArea.selected">
                    <div class="selectBorn-container">
                        <ion-label class="selectBorn-title" (click)="selectChargeArea(chargeArea)">
                            <div class="large-ghost-size selectBorn-content">
                                <h6 class="ghost-hidden"><b>{{chargeArea.distanceInKm | kilometerToDistance:'1.0-0'}}</b></h6>
                                <h5 class="ghost-hidden">
                                    <ion-img alt="" src="assets/images/png/icon-localisation.png"></ion-img>
                                    <span>{{chargeArea.name}}</span>
                                </h5>
                            </div>
                            <div>
                                <ion-icon name="caret-up" [hidden]="!chargeArea.selected"></ion-icon>
                                <ion-icon name="caret-down" [hidden]="chargeArea.selected"></ion-icon>
                            </div>
                        </ion-label>

                        <div>
                            <div>
                                <p class='registerLabel-title-amount selectBorn-location'
                                   [hidden]="!chargeArea.selected">
                                    {{chargeArea.address | location}}</p>
                            </div>

                            <ion-row class="selectBorn-born" *ngFor="let station of chargeArea.stations;
                            trackBy: trackByStationId"
                                     [hidden]="!chargeArea.selected"
                                     (click)="selectStation(station)">
                                <ion-col class="checkbox-info ghost-hidden">
                                    <p>{{'infra.station' | translate: {stationVisualId: station.visualId} }}</p>
                                </ion-col>
                                <ion-col class="selectBorn-born-start ghost-hidden ion-text-right">
                                    <ion-fab color="primary">
                                        <ion-fab-button>
                                            <i class="icon-play"></i>
                                        </ion-fab-button>
                                    </ion-fab>
                                </ion-col>
                            </ion-row>
                        </div>
                    </div>
                </ion-item>
            </ion-list>

            <div class="emptyLocation" *ngIf="locationUnavailable">
                <ion-icon name="locate"></ion-icon>
                {{'station.locationUnavailable' | translate}}
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>


<ion-footer class="ion-no-border ion-text-center">
    <ion-toolbar>
        <ion-button color="secondary" expand="block" fill="outline" (click)="goToHome()" class="previous">
            {{'common.cancel' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>


<ng-template #spinner>
    <ion-spinner color="light"></ion-spinner>
</ng-template>

<ng-template #noNearestChargeArea>
    <div class="emptyLocation" *ngIf="!locationUnavailable">
        <ion-icon name="locate"></ion-icon>
        {{'station.noNearestChargeArea' | translate}}
    </div>
</ng-template>
