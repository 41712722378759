<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToBaseUrl()"></izivia-back-button>

        <ion-title>{{"charge.retry.title" | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ng-container *ngIf="!remoteStart; else remoteStartContainer">
    <ion-content>
        <ion-card>
            <ion-card-content>
                <div class="bold">{{'charge.retry.noLaunch' | translate}}</div>
                <div>{{'charge.retry.retry' | translate}}</div>
            </ion-card-content>
        </ion-card>

    </ion-content>

    <ion-footer class="ion-no-border ion-text-center">
        <ion-toolbar>
            <ion-button color="secondary"
                        (click)="showRemoteStart()">
                {{'charge.retry.button.retrySameStation' | translate}}
            </ion-button>
            <ion-button color="secondary" fill="outline"
                        (click)="restartTunnel()">
                {{'charge.retry.button.retryOtherStation' | translate}}
            </ion-button>
        </ion-toolbar>
    </ion-footer>
</ng-container>

<ng-template #remoteStartContainer>
    <ion-content>
        <ion-card>
            <ion-card-content class="bottomMenuView">
                <remote [hidden]="!remoteRequest"
                        [action]="Action.START"
                        [request]="remoteRequest"
                        (remoteResponse)="handleRemoteResponse($event)"></remote>
            </ion-card-content>
        </ion-card>
    </ion-content>
</ng-template>
