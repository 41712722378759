<form #getMediaForm="ngForm" class="form-content">
    <h4 class="title">{{'charge.mediaChoice.email' | translate}}<span class="required">*</span></h4>
    <ion-item class="input-item ion-no-padding">
        <ion-input enterkeyhint="done" type="email"
                   [(ngModel)]="userEmail"
                   (ngModelChange)="onUserEmailChange($event)"
                   name="email"
                   iziviaEmailValidator
                   required>
        </ion-input>
    </ion-item>

    <izivia-help-text>{{'charge.mediaChoice.info' | translate}}</izivia-help-text>

    <ion-item *ngIf="termsAndConditionsCode" class="no-border input-item ion-no-padding" lines="none">
        <checkbox [(ngModel)]="termsAndConditionsAccepted"
                  name="termsAndConditions"
                  [required]="true"></checkbox>
        <ion-label class="ion-text-wrap chargeMedia-content-label">
            <p class="chargeMedia-content-label-subcontent">{{'charge.mediaChoice.termsAndConditionsMessage' | translate}}
                <a class="link" tappable
                   (click)="openCgu()">{{'charge.mediaChoice.termsAndConditionsLink' | translate}}</a>
            </p>
        </ion-label>
    </ion-item>
</form>
