import {NgModule} from '@angular/core';
import {ConfigAppService} from './configApp.service';
import {FrontEndService} from './frontEnd.service';
import {StationSearcherService} from './business/stationSearcher.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        ConfigAppService,
        FrontEndService,
        StationSearcherService
    ],
    exports: []
})
export class ServicesModule {
}
