import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {CONTEXT_KEY_PARAM, NewChargeContextService} from 'lib-mobile';
import {STATION_PATH_PARAM} from '../../chargeAreas.routes';

@Injectable()
export class ChargePointTypeChoiceGuard implements CanActivate, CanActivateChild {
    constructor(private readonly router: Router,
        private readonly newChargeContextService: NewChargeContextService) {

    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return this.checkChargePointChoiceIsSet(route);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return this.checkChargePointChoiceIsSet(route);
    }

    private checkChargePointChoiceIsSet(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const contextId = route.queryParamMap.get(CONTEXT_KEY_PARAM);
        const newChargeContext = this.newChargeContextService.getNewChargeContext(contextId);

        // Already handle by NewChargeContextGuard
        if (!newChargeContext) {
            return false;
        }

        if (!newChargeContext.chargePoint) {
            return this.router.createUrlTree([`/app/new-charge/station/${route.paramMap.get(STATION_PATH_PARAM)}/plug-type-choice`], {
                queryParamsHandling: 'preserve'
            });
        }

        return true;
    }
}

