import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {appendAnonymousHeader, HOST_THIRD_PARTY_ID} from 'lib-front';

@Injectable()
export class AnonymousInterceptor implements HttpInterceptor {
    constructor(@Inject(HOST_THIRD_PARTY_ID) private readonly thirdPartyId: string) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = appendAnonymousHeader(this.thirdPartyId, req.headers);
        return next.handle(req.clone({
            headers: headers
        }));
    }
}
