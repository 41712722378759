import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {StationResolver} from './station-resolver.service';
import {PlugTypeChoiceView} from './newCharge/plugTypeChoice/plugTypeChoice.view';
import {ChargePointChoiceView} from './newCharge/chargePointTypeChoice/chargePointChoice.view';
import {MediaChoiceView} from './newCharge/mediaChoice/mediaChoice.view';
import {NewChargeSummaryView} from './newCharge/summary/summary.view';
import {NewChargeContextResolver} from './newCharge/newChargeContext.resolver';
import {NewChargeContextGuard} from './newCharge/newChargeContext.guard';
import {MobileComponentsModule} from 'lib-mobile';
import {StationChoiceView} from './newCharge/stationChoice/stationChoice.view';
import {ComponentsModule} from '../../components/components.module';
import {ReservationChoiceView} from './newCharge/reservationChoice/reservationChoice.view';
import {LibFrontModule} from 'lib-front';
import {PlugTypeChoiceGuard} from './newCharge/plugTypeChoice/plugTypeChoice.guard';
import {ChargePointTypeChoiceGuard} from './newCharge/chargePointTypeChoice/chargePointTypeChoice.guard';
import { GetMediaComponent } from './newCharge/mediaChoice/getMedia/getMedia.component';
import { EnterMediaComponent } from './newCharge/mediaChoice/enterMedia/enterMedia.component';
import {RetryView} from './newCharge/retry/retry.view';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        MobileComponentsModule,
        LibFrontModule,
        ComponentsModule,
        IonicModule
    ],
    declarations: [
        StationChoiceView,
        PlugTypeChoiceView,
        ChargePointChoiceView,
        MediaChoiceView,
        NewChargeSummaryView,
        ReservationChoiceView,
        GetMediaComponent,
        EnterMediaComponent,
        RetryView
    ],
    providers: [
        StationResolver,
        NewChargeContextResolver,
        NewChargeContextGuard,
        PlugTypeChoiceGuard,
        ChargePointTypeChoiceGuard
    ]
})
export class ChargeAreasModule {
}
