<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToHome()"></izivia-back-button>

        <ion-title>{{'charge.mediaChoice.buyOtp' | translate}}</ion-title>
        <ion-buttons slot="end">
            <support-button [color]="'white'"></support-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card>
        <paynow-charge-header [newChargeContext]="newChargeContext"
                              [station]="station"
                              [step]="step"></paynow-charge-header>
    </ion-card>

    <ng-container *ngIf="fetchingOtpPrice || isAutoValidatingOtp">
        <ion-row>
            <ion-col style="text-align: center">
                <ion-spinner></ion-spinner>
            </ion-col>
        </ion-row>
    </ng-container>

    <!-- OTP (when REMOTE_START action is not available or when user is anonymous) -->
    <ng-container *ngIf="otpPrice && !fetchingOtpPrice && !isAutoValidatingOtp && !isGoingToNextSlide">
        <ion-card>
            <ion-card-header>
                <ion-segment color="secondary" [(ngModel)]="tabSelected">
                    <ion-segment-button href="#getMedia" value="getMedia">
                        {{'charge.mediaChoice.getMedia' | translate}}
                    </ion-segment-button>
                    <ion-segment-button href="#enterMedia" value="enterMedia">
                        {{'charge.mediaChoice.enterMedia' | translate}}
                    </ion-segment-button>
                </ion-segment>
            </ion-card-header>
            <ion-card-content>
                <getMedia
                    [hidden]="tabSelected !== 'getMedia'"
                    [userEmail]="userEmail"
                    [termsAndConditionsAccepted]="termsAndConditionsAccepted"
                    [termsAndConditionsCode]="termsAndConditionsCode"
                    [newChargeContext]="newChargeContext"
                    [otpPrice]="otpPrice"
                    [hasPricingInfo]="hasPricingInfo"
                    [station]="station"
                    (userEmailChanged)="updateUserEmail($event)"
                    (isGetMediaFormValid)="onMediaFormValidationChanged($event)"
                    (cguOpened)="openCgu()">
                </getMedia>
                <enterMedia
                    [otpCode]="otpCode"
                    [userEmail]="userEmail"
                    [otpError]="otpError"
                    [otpErrorKey]="otpErrorKey"
                    [hidden]="tabSelected !== 'enterMedia'"
                    (otpCodeChanged)="onOtpCodeChanged($event)"
                    (userEmailChanged)="updateUserEmail($event)">
                </enterMedia>
            </ion-card-content>
        </ion-card>
        <ion-card class="card-grey" [hidden]="tabSelected !== 'getMedia'">
            <ion-card-content>
                <ion-item class="chargeMedia-content">
                    <i class="chargeMedia-content-icon icon-outlet"></i>

                    <ion-label class="chargeMedia-content-label">
                        <p class="chargeMedia-content-label-title">
                            {{'plugType.' + newChargeContext.plugTypeChoice.plugType.name | translate}}
                            -
                            {{newChargeContext.chargePoint | stationChargePoint}}
                        </p>
                        <p class="chargeMedia-content-label-subcontent">
                            <span *ngIf="newChargeContext.chargePoint?.maxPowerInW">{{'chargePoint.maxPower' | translate}}</span>
                            {{newChargeContext.chargePoint?.maxPowerInW | powerMaxInWToKw}}
                        </p>
                    </ion-label>
                </ion-item>
                <ion-item *ngIf="newChargeContext.hasReservation() && otpPrice?.reservationPriceWithVAT >= 0"
                          class="chargeMedia-content">
                    <ion-icon class="chargeMedia-content-icon" name="time-outline"></ion-icon>
                    <ion-label class="chargeMedia-content-label">
                        <p class="chargeMedia-content-label-title">{{'charge.reservationChoice.title' | translate}}</p>
                        <p class="chargeMedia-content-label-subcontent">
                            {{newChargeContext?.reservation?.durationInMinutes | duration :{report: true, minutes: true, seconds: false, entryUnit: 'm'} }}
                            -
                            {{otpPrice?.reservationPriceWithVAT | currency:undefined: otpPrice?.currencyUnit}}
                        </p>
                    </ion-label>
                </ion-item>
                <ion-item class="chargeMedia-content" *ngIf="hasPricingInfo">
                    <i class="chargeMedia-content-icon icon-account-euro"></i>
                    <ion-label class="chargeMedia-content-label">
                        <p class="chargeMedia-content-label-title">{{'charge.mediaChoice.chargeRate' |
                            translate}}</p>
                        <p class="chargeMedia-content-label-subcontent">
                            <pricing-context-formatter [pricingContext]="station?.pricingContext"></pricing-context-formatter>
                        </p>
                    </ion-label>
                </ion-item>

                <ion-item class="chargeMedia-content">
                    <ion-icon class="chargeMedia-content-icon" name="card"></ion-icon>
                    <ion-label class="chargeMedia-content-label">
                        <p class="chargeMedia-content-label-title">
                            {{'charge.mediaChoice.chargeCode' | translate}}
                        </p>
                        <p class="chargeMedia-content-label-subcontent">
                            {{otpPrice?.otpPriceWithVAT | currency:undefined:otpPrice?.currencyUnit || ''}}
                        </p>
                    </ion-label>
                </ion-item>
            </ion-card-content>
        </ion-card>
    </ng-container>

    <ng-container *ngIf="otpPriceTextError">
        <ion-card class="emptyCard tunnelView">
            <ion-card-content class="tunnelView">
                <ion-label>
                    <div class="emptyCardImg">
                        <ion-img class="emptyCardImg" src="assets/images/svg/izivia-pass.svg" alt=""></ion-img>
                    </div>
                    <p class="ion-text-center">{{otpPriceTextError}}</p>
                    <p class="ion-text-center" *ngIf="newChargeContext?.chargePoint?.stationAlias">
                        {{'charge.mediaChoice.stationId' | translate:  {alias: newChargeContext.chargePoint.stationAlias} }}</p>
                </ion-label>
                <ion-button slot="start" color="secondary" expand="block"
                            *ngIf="supportContacts" (click)="openSupportModal()">
                    {{'charge.mediaChoice.contact' | translate}}
                </ion-button>
            </ion-card-content>
        </ion-card>
    </ng-container>
</ion-content>

<ion-footer class="ion-no-border ion-text-center">
    <ion-toolbar>
        <ion-button [hidden]="tabSelected !== 'getMedia'" slot="start" color="secondary" expand="block" class="previous"
                    (click)="goToPreviousSlide()"
                    [disabled]="payingOtp || orderingOtp">
            {{'common.previous' | translate}}
        </ion-button>
        <ion-button [hidden]="tabSelected !== 'enterMedia'" slot="start" color="secondary" class="previous"
                    (click)="goToPreviousSlide()"
                    [disabled]="payingOtp || orderingOtp">
            {{'common.previous' | translate}}
        </ion-button>
        <ion-button slot="end" color="secondary" expand="block" class="next"
                    (click)="goToNextSlide()"
                    [disabled]="!canGoNext()"
                    *ngIf="otpPrice === null">
            {{'common.next' | translate}}
        </ion-button>
        <ion-button slot="start" color="secondary" expand="block" class="order"
                    (click)="order()"
                    [disabled]="orderingOtp"
                    *ngIf="otpPrice && isOtpClient()">
            <ng-container *ngIf="!orderingOtp; else spinner">
                {{'charge.mediaChoice.order' | translate}}
            </ng-container>
        </ion-button>
        <ion-button slot="end" color="secondary" expand="block" class="pay"
                    [hidden]="tabSelected !== 'getMedia'"
                    (click)="pay()"
                    [disabled]="!canPay()"
                    *ngIf="otpPrice && isOtpCb()">
            <ng-container *ngIf="!payingOtp; else spinner">
                <ng-container *ngIf="otpPrice?.totalPriceWithVAT > 0">
                    {{'charge.mediaChoice.pay' | translate}}
                </ng-container>
                <ng-container *ngIf="otpPrice?.totalPriceWithVAT <= 0 && !otpPrice?.freeCharge">
                    {{'charge.mediaChoice.authorize' | translate}}
                </ng-container>
                <ng-container *ngIf="otpPrice?.totalPriceWithVAT <= 0 && otpPrice?.freeCharge">
                    {{'common.next' | translate}}
                </ng-container>
            </ng-container>
        </ion-button>
        <ion-button slot="end" color="secondary" expand="block"
                    (click)="checkOtpIsValid()"
                    [disabled]="isCheckingOtp || !userEmail || !otpCode"
                    [hidden]="tabSelected !== 'enterMedia'">
            {{'charge.mediaChoice.checkOtp' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>

<ng-template #spinner>
    <ion-spinner></ion-spinner>
</ng-template>
