import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FrontStationHttpService, IziviaLoggerService, StationDto, StationLazyCategory, UserAction} from 'lib-front';
import {CONTEXT_KEY_PARAM, NewChargeContextService} from 'lib-mobile';
import {STATION_PATH_PARAM} from './chargeAreas.routes';
import {catchError, tap} from 'rxjs/operators';

@Injectable()
export class StationResolver implements Resolve<StationDto | null> {
    constructor(
        private readonly stationService: FrontStationHttpService,
        private readonly newChargeContextService: NewChargeContextService,
        private readonly loggerService: IziviaLoggerService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StationDto | null> {
        const stationId = route.paramMap.get(STATION_PATH_PARAM);
        let chargeContext = this.newChargeContextService.getNewChargeContext(route.queryParamMap.get(CONTEXT_KEY_PARAM));

        if (!chargeContext) {
            chargeContext =
                this.newChargeContextService.getNewChargeContext();
        }

        if (chargeContext && !chargeContext.stationId) {
            chargeContext.stationId = stationId;
        }

        return this.stationService.findStation(stationId, [
            StationLazyCategory.USER_ACTIONS,
            StationLazyCategory.CURRENT_USER_ACTIONS,
            StationLazyCategory.PRICING_CONTEXT,
            StationLazyCategory.PLUG_TYPES,
            StationLazyCategory.OTP_ALLOWED_STATUSES
        ])
            .pipe(
                tap(station => {
                    chargeContext.isReservable = station.currentUserActions.includes(UserAction.RESERVE);
                }),
                catchError(err => {
                    this.loggerService.debug('Could not resolve station...', err);

                    // Catch error and turns it into a valid null value to avoid blank screen: all depending view(s) should handle the null case
                    // to improve user experience (by redirecting to another route for instance).
                    return of(null);
                })
            );
    }
}
