import {Component, Inject, OnInit} from '@angular/core';
import {
    FaqFolderDetailDto,
    FaqHttpService,
    FaqNodeDetailDto,
    FaqNodeDto,
    FaqNodeType,
    FaqPageDetailDto,
    HOST_THIRD_PARTY_ID,
    isFaqFolderDetail,
    isFaqPageDetail,
    SupportContact,
    SupportContactBusinessService
} from 'lib-front';
import {NavController, ViewDidEnter, ViewWillEnter} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {GhostComponent} from 'lib-mobile';

@Component({
    selector: 'faq-node.view',
    templateUrl: './faqNode.view.html',
    styleUrls: ['./faqNode.view.scss'],
})
export class FaqNodeView extends GhostComponent implements OnInit, ViewWillEnter, ViewDidEnter {
    faqNodeDetailDto: FaqNodeDetailDto;

    supportContacts: SupportContact[];


    isFaqFolderDetail = isFaqFolderDetail;
    isFaqPageDetail = isFaqPageDetail;

    constructor(private readonly faqHttpService: FaqHttpService,
        private readonly supportContactService: SupportContactBusinessService,
        @Inject(HOST_THIRD_PARTY_ID) private readonly hostThirdPartyId: string,
        private readonly navController: NavController,
        private readonly route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.supportContactService.findSupportContacts(this.hostThirdPartyId)
            .subscribe(supportContacts => this.supportContacts = supportContacts);
    }

    ionViewWillEnter(): void {
        this.ghostMode = true;
    }

    ionViewDidEnter(): void {
        this.faqHttpService.findNode(this.route.snapshot.paramMap.get('faqNodeId'))
            .pipe(finalize(() => this.ghostMode = false))
            .subscribe(faqNodeDetailDto => {
                this.faqNodeDetailDto = faqNodeDetailDto;
            });
    }

    goToFaqNode(faqNode: FaqNodeDto): void {
        this.navController.navigateForward(`help/faq/${faqNode.id}`);
    }

    generateGhost(): void {
        this.faqNodeDetailDto = {
            id: '',
            '@type': FaqNodeType.PAGE,
            title: '',
            content: ''
        } as FaqPageDetailDto;
    }
}
