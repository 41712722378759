<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToMap()"></izivia-back-button>
        <ion-title>{{'charge.reservationChoice.title' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card>
        <paynow-charge-header [newChargeContext]="newChargeContext"
                              [station]="station"
                              [step]="step"></paynow-charge-header>
    </ion-card>

    <reservation-choice *ngIf="reservationDurations"
                        [(ngModel)]="reservationDurationSelected"
                        [ghostMode]="fetchingReservationDuration"
                        [reservationDurations]="reservationDurations"></reservation-choice>
    <div *ngIf="!isReservationAvailable">
        <ion-card>
            <ion-card-content>
                <div class="noReservation">
                    {{'reservation.noReservation' | translate}}
                </div>
            </ion-card-content>
        </ion-card>
    </div>
</ion-content>
<ion-footer class="ion-no-border">
    <ion-toolbar>
        <ion-button (click)="goToPreviousSlide()" class="previous" color="secondary" expand="block" slot="start">
            {{'common.previous' | translate}}
        </ion-button>
        <ion-button (click)="goToNextSlide()" [disabled]="!canGoNext()" class="next" color="secondary" expand="block" slot="start">
            {{'common.next' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>

