<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToHome()"></izivia-back-button>

        <ion-title>{{'remote.stop.title' | translate}}</ion-title>
        <ion-buttons slot="end">
            <support-button [color]="'white'"></support-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>


<ion-content>
    <ion-card>
        <ion-card-content class="bottomMenuView">
            <div class="chargeCar">
                <ion-img class="chargeCar-loader" src="assets/images/gif/loading-car.gif" alt=""></ion-img>
                <p class="ion-text-center">
                    <span>{{'remote.stop.waitingMessage' | translate}}</span>
                </p>
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>


<ion-footer class="ion-no-border">
    <ion-toolbar>
        <ion-buttons>
            <ion-button color="secondary"  fill="solid" slot="start" (click)="presentRemoteStop()">
                {{'common.done' | translate}}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
