<form class="form-content">
    <h4 class="title">{{'charge.mediaChoice.otpCode' | translate}}<span class="required">*</span></h4>
    <ion-item class="input-item ion-no-padding">
        <ion-input enterkeyhint="done"
                   type="text"
                   [ngModel]="otpCode"
                   (ngModelChange)="otpCodeChanged.next($event)"
                   name="otpCode"
                   placeholder="{{'charge.mediaChoice.otpPlaceholder' | translate}}">
        </ion-input>
    </ion-item>
    <p *ngIf="otpError" class="error">{{otpErrorKey | translate}}</p>

    <h4 class="title">{{'charge.mediaChoice.email' | translate}}<span class="required">*</span></h4>
    <izivia-help-text>{{'charge.mediaChoice.emailUsedToBuyMessage' | translate}}</izivia-help-text>
    <ion-item class="input-item ion-no-padding">
        <ion-input enterkeyhint="done"
                   type="text"
                   [ngModel]="userEmail"
                   (ngModelChange)="userEmailChanged.next($event)"
                   name="userEmail"
                   placeholder="{{'charge.mediaChoice.emailUsedToBuyPlaceHolder' | translate}}">
        </ion-input>
    </ion-item>

    <ng-template #spinner>
        <ion-spinner color="light"></ion-spinner>
    </ng-template>
</form>
