<div>
    <div *ngIf="station">
        <div class="stationInfo">
            <div class="stationInfo-network">
                <div class="stationInfo-network-label"
                     [class.empty-chargeName]="!(station?.logoRef)">{{station?.chargeAreaName}}</div>
            </div>

            <div class="stationInfo-address">
                <p class="stationInfo-address-localisation"
                   [class.empty-chargeName]="!(station?.logoRef)">{{station?.address | location}}</p>
            </div>

            <div class="stationInfo-chargeName" [class.empty-chargeName]="!(station?.logoRef)">
                <remote-img class="station-container-img"
                            [src]="'/api/download/' + station.logoRef"
                            [ghostClass]="'round'"
                            [alt]=""
                            *ngIf="station?.logoRef"></remote-img>
            </div>
        </div>
    </div>

    <header-steps [steps]="headerSteps"
                  [context]="newChargeContext"
                  [currentChargeStepType]="step"
                  [disableNavigation]="step === NewChargeStepType.SUMMARY"></header-steps>

</div>





