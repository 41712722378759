import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NavController, ViewWillEnter} from '@ionic/angular';
import {NewChargeContext, RemoteInfoMedia} from 'lib-mobile';
import {
    Action, IziviaLoggerService, OTPSession, RemoteResponse, RemoteStatus,
    StationDto
} from 'lib-front';
@Component({
    selector: 'izivia-retry',
    templateUrl: './retry.view.html',
    styleUrls: ['./retry.view.scss']
})
export class RetryView implements ViewWillEnter {

    newChargeContext: NewChargeContext;
    station: StationDto;
    remoteStart: boolean;
    remoteRequest: RemoteInfoMedia;
    constructor(private readonly route: ActivatedRoute,
        private readonly navController: NavController,
        private readonly loggerService: IziviaLoggerService) {}

    ionViewWillEnter() {
        this.station = this.route.snapshot.data.station;
        this.newChargeContext = this.route.snapshot.data.newChargeContext;
    }

    goToBaseUrl(): void {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    showRemoteStart() {
        this.remoteStart = true;

        const otpSession: OTPSession = this.newChargeContext.otpSession;

        this.remoteRequest = new RemoteInfoMedia(
            otpSession.stationRef,
            otpSession.chargePointRef,
            otpSession.plugRef,
            otpSession.otpCode
        );
    }

    restartTunnel() {
        this.newChargeContext.isRetryOnOtherStation = true;

        this.navController.navigateRoot([`/app/new-charge/station-choice`], {
            replaceUrl: true,
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    protected readonly Action = Action;

    handleRemoteResponse(remoteResponse: RemoteResponse) {
        if (remoteResponse.status === RemoteStatus.DONE) {
            if (remoteResponse.chargeRef) {
                const url = `/events/details/charge/${remoteResponse.chargeRef}/otp/${this.newChargeContext.otpSession.otpCode}`;
                this.navController.navigateRoot(url, {replaceUrl : true, animationDirection : 'forward'});
            } else {
                this.loggerService.error('[handleRemoteResponse]: remoteResponse.chargeRef === null', remoteResponse);
                this.navController.navigateRoot('/home',
                    {replaceUrl : true, animationDirection : 'forward'});
            }
        } else if (remoteResponse.status === RemoteStatus.TIMEOUT) {
            this.remoteStart = false;
            this.remoteRequest = null;
            this.loggerService.debug('[handleRemoteResponse] TIMOUT', remoteResponse);
            this.navController.navigateForward([`/app/new-charge/station/${this.station.id}/retry`], {
                onSameUrlNavigation: 'reload', queryParams: this.newChargeContext.toQueryParam()
            });
        } else {
            this.remoteStart = false;
            this.remoteRequest = null;
            this.navController.navigateForward([`/app/new-charge/station/${this.station.id}/retry`], {
                onSameUrlNavigation: 'reload', queryParams: this.newChargeContext.toQueryParam()
            });
        }
    }
}
