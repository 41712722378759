<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToHome()"></izivia-back-button>
        <ion-title>{{'help.title' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card class="helpContent">
        <ion-item>
            <ion-text>{{'faq.description' | translate}}</ion-text>
        </ion-item>
        <ion-grid class="ion-text-center">
            <ion-row class="helpContent-icon">
                <ion-col size-xs="6" tappable (click)="openSupportChoice()" *ngIf="supportContacts">
                    <i class="icon-assistance"></i>
                    <h6>{{'support.title' | translate}}</h6>
                </ion-col>
                <ion-col size-xs="6" tappable [routerLink]="['/help/faq']" *ngIf="isFaqAvailable">
                    <i class="icon-faq"></i>
                    <h6>{{'faq.title' | translate}}</h6>
                </ion-col>
                <ion-col size-xs="6"
                         tappable
                         *ngIf="frontEndTermsAndConditionsI18nCode"
                         (click)="open(frontEndTermsAndConditionsI18nCode)">
                    <i class="icon-justice"></i>
                    <h6>{{'legalNotice.title' | translate}}</h6>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card>
</ion-content>
