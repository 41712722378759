<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToHome()"></izivia-back-button>

        <ng-container [ngSwitch]="eventType">
            <ion-title *ngSwitchCase="'charge'">{{'events.details.charge.title' | translate}}</ion-title>
            <ion-title *ngSwitchCase="'otp'">{{'events.details.otp.title' | translate}}</ion-title>
            <ion-title *ngSwitchCase="'reservation'">{{'events.details.reservation.title' | translate}}</ion-title>
        </ng-container>
        <ion-buttons slot="end">
            <support-button [color]="'white'"></support-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [class.ghost]="ghostMode">
    <ng-container [ngSwitch]="eventType">
        <charge-record-details *ngSwitchCase="'charge'"
                               [chargeRecord]="eventDetails | asChargeRecord"
                               [ghostMode]="ghostMode"
                               [pollingTimerInSecond]="pollingTimerInSecond"
                               (remoteStoppedEvent)="remoteDoneEvent()">
        </charge-record-details>

        <otp-details *ngSwitchCase="'otp'"
                     [otpSession]="eventDetails | asOtpSession"
                     [ghostMode]="ghostMode"
                     (remoteDoneEvent)="remoteDoneEvent($event)">
        </otp-details>

        <reservation-details (remoteDoneEvent)="remoteDoneEvent($event)"
                             *ngSwitchCase="'reservation'"
                             [ghostMode]="ghostMode"
                             [reservationDetail]="eventDetails | asReservationDetail">
        </reservation-details>
    </ng-container>
</ion-content>
