import {Route} from '@angular/router';
import {HelpView} from './help.view';
import {FaqView} from './faq/faq.view';
import {FaqNodeView} from './faqNode/faqNode.view';

export const helpRoutes: Array<Route> = [
    {
        path: '', component: HelpView
    },
    {
        path: 'faq', component: FaqView
    },
    {
        path: 'faq/:faqNodeId', component: FaqNodeView
    }
];
