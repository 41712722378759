import {Component, Input} from '@angular/core';
import {StationDto} from 'lib-front';
import {HeaderStep, NewChargeContext, NewChargeStepType} from 'lib-mobile';

@Component({
    selector: 'paynow-charge-header',
    templateUrl: './paynowChargeHeader.component.html',
    styleUrls: ['./paynowChargeHeader.component.scss']
})
export class PaynowChargeHeaderComponent {
    NewChargeStepType = NewChargeStepType;

    @Input() set station(station: StationDto | null) {
        this._station = station;
        this.computeAndSetHeaderStep();
    }
    @Input() step: NewChargeStepType;
    @Input() set newChargeContext(newChargeContext: NewChargeContext) {
        this._newChargeContext = newChargeContext;
        this.computeAndSetHeaderStep();
    }

    headerSteps: HeaderStep[] = PaynowChargeHeaderComponent.createSteps('', false);

    private _newChargeContext: NewChargeContext;
    private _station: StationDto | null;

    constructor() {}

    private static createSteps(stationId: string, isReservable: boolean): HeaderStep[] {
        const steps: HeaderStep[] = [{
            urlView: `/app/new-charge/station-choice`,
            icon: 'icon-borne',
            isNotIonIcon: true,
            newChargeStepType: NewChargeStepType.STATION_CHOICE,
            contextFieldToResetIfGoingPreviousStep: ['plugTypeChoice', 'chargePoint', 'mediaSummary', 'otpSession']
        }, {
            urlView: `/app/new-charge/station/${stationId}/plug-type-choice`,
            icon: 'icon-outlet',
            isNotIonIcon: true,
            newChargeStepType: NewChargeStepType.PLUG_TYPE_CHOICE,
            contextFieldToResetIfGoingPreviousStep: ['chargePoint', 'mediaSummary', 'otpSession']
        }, {
            urlView: `/app/new-charge/station/${stationId}/charge-point-choice`,
            icon: 'battery-charging',
            newChargeStepType: NewChargeStepType.CHARGE_AREA_CHOICE,
            contextFieldToResetIfGoingPreviousStep: ['mediaSummary', 'otpSession']

        }];

        if (isReservable) {
            steps.push({
                urlView: `/app/map/station/${stationId}/new-charge/reservation-choice`,
                icon: 'time-outline',
                newChargeStepType: NewChargeStepType.RESERVATION_CHOICE,
                contextFieldToResetIfGoingPreviousStep: []
            });
        }
        steps.push({
            urlView: `/app/new-charge/station/${stationId}/media-choice`,
            icon: 'card',
            newChargeStepType: NewChargeStepType.MEDIA_CHOICE,
            contextFieldToResetIfGoingPreviousStep: []
        }, {
            urlView: null,
            icon: 'checkmark-circle-outline',
            newChargeStepType: NewChargeStepType.SUMMARY,
            contextFieldToResetIfGoingPreviousStep: []
        });
        return steps;
    }

    private computeAndSetHeaderStep() {
        this.headerSteps = PaynowChargeHeaderComponent.createSteps(
            this.station && this.station.id ? this.station.id : '', this.newChargeContext?.isReservable);
    }

    get station(): StationDto | null {
        return this._station;
    }

    get newChargeContext():  NewChargeContext {
        return this._newChargeContext;
    }
}
