import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ViewsModule} from './views/views.module';
import {AppRootComponent} from './views/appRoot.component';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {DefaultUrlSerializer, RouteReuseStrategy, UrlSerializer} from '@angular/router';
import {
    ApiInterceptor,
    DeviceInterceptor,
    FRONT_ORIGIN,
    FrontOrigin,
    FrontOriginInterceptor,
    FrontStorageService,
    HOST_THIRD_PARTY_ID,
    IziviaDeviceService,
    THEME,
    UNAUTHORIZED_REDIRECT_PATH
} from 'lib-front';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage';
import {WebView} from '@awesome-cordova-plugins/ionic-webview/ngx';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {
    createIziviaErrorHandler,
    EVENT_REQUEST_PERIOD_IN_MS,
    FAQ_DEBOUNCE_IN_MS,
    FAQ_MINIMUM_LENGTH_BEFORE_SEARCH,
    FAQ_SEARCH_LIMIT,
    IZIVIA_DEFAULT_ENVIRONMENT,
    IZIVIA_ENVIRONMENTS,
    IziviaMobileDeviceService,
    IziviaPlatformService,
    IziviaReloadPermissionService,
    IziviaStorageService,
    LibMobileModule,
    NOTIFICATION_REQUEST_PERIOD_IN_MS,
    NOTIFICATION_SEARCH_LIMIT,
    PAYMENT_LIBRARY_LOADING_TIMEOUT_IN_MS,
    REMOTE_REQUEST_PERIOD_IN_MS
} from 'lib-mobile';
import {AnonymousInterceptor} from './utils/anonymous.interceptor';
import {createTranslateLoader, getLocaleId} from './services/i18n/i18n.function';
import {ServicesModule} from './services/services.module';
import {ComponentsModule} from './components/components.module';
import {ConfigAppService} from './services/configApp.service';
import {theme} from '../theme';
import {LanguageInterceptor} from './interceptors/language.interceptor';
import {MatomoInitializationMode, NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';

registerLocaleData(localeFr, 'fr');

const IZIVIA_UNAUTHORIZED_REDIRECT_PATH: string = '/';

export function configAppFactory(config: ConfigAppService): () => Promise<any> {
    return () => config.init();
}

/**
 * Http interceptors are declared by priority order (first is called before last)
 * @see https://angular.io/guide/http#interceptor-order
 */
const httpInterceptorProviders: Provider[] = [
    // URL
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    // Standard headers
    {provide: HTTP_INTERCEPTORS, useClass: AnonymousInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    // Custom headers
    {provide: HTTP_INTERCEPTORS, useClass: FrontOriginInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DeviceInterceptor, multi: true}
];

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ServicesModule,
        ComponentsModule,
        ViewsModule,
        IonicModule.forRoot(),
        LibMobileModule,
        IonicStorageModule.forRoot({
            name: '_iziviastorage',
            driverOrder: ['indexeddb', 'localstorage']
        }),
        NgxMatomoTrackerModule.forRoot({
            mode: MatomoInitializationMode.AUTO_DEFERRED,
            runOutsideAngularZone: true
        }),
        NgxMatomoRouterModule
    ],
    providers: [
        ConfigAppService,
        {
            provide: APP_INITIALIZER,
            deps: [ConfigAppService],
            useFactory: configAppFactory,
            multi: true
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, deps: [TranslateService], useFactory: (getLocaleId)},
        {provide: FRONT_ORIGIN, useValue: FrontOrigin.PAYNOW_V3},
        {provide: HOST_THIRD_PARTY_ID, useValue: theme.thirdPartyId},
        {provide: THEME, useValue: theme},
        {provide: UNAUTHORIZED_REDIRECT_PATH, useValue: IZIVIA_UNAUTHORIZED_REDIRECT_PATH},
        {
            provide: IZIVIA_ENVIRONMENTS,
            deps: [ConfigAppService],
            useFactory: (configService: ConfigAppService) => configService.getIziviaEnvs()
        },
        {
            provide: IZIVIA_DEFAULT_ENVIRONMENT,
            deps: [ConfigAppService],
            useFactory: (configService: ConfigAppService) => configService.getIziviaDefaultEnv()
        },
        {provide: UrlSerializer, useClass: DefaultUrlSerializer},
        {
            provide: EVENT_REQUEST_PERIOD_IN_MS,
            // in milliseconds
            useValue: 2 * 60 * 1000
        },
        {
            provide: NOTIFICATION_REQUEST_PERIOD_IN_MS,
            // in milliseconds
            useValue: 2 * 60 * 1000
        },
        {
            provide: NOTIFICATION_SEARCH_LIMIT,
            useValue: 10
        },
        httpInterceptorProviders,
        WebView,
        Device,
        InAppBrowser,
        {
            provide: PAYMENT_LIBRARY_LOADING_TIMEOUT_IN_MS,
            useValue: 15 * 1000
        },
        {
            provide: REMOTE_REQUEST_PERIOD_IN_MS,
            useValue: 5 * 1000
        },
        {
            provide: FAQ_MINIMUM_LENGTH_BEFORE_SEARCH,
            useValue: 3
        },
        {
            provide: FAQ_SEARCH_LIMIT,
            useValue: 10
        },
        {
            provide: FAQ_DEBOUNCE_IN_MS,
            useValue: 250
        },
        {provide: FrontStorageService, useClass: IziviaStorageService},
        {
            provide: ErrorHandler,
            useFactory: createIziviaErrorHandler,
            deps: [IziviaPlatformService, IziviaReloadPermissionService]
        },
        {provide: IziviaDeviceService, useClass: IziviaMobileDeviceService}
    ],
    bootstrap: [AppRootComponent]
})
export class AppModule {
    constructor() {
        /*
        Should never inject anything in the AppModule Constructor
        because the constructor it's call before the APP_INITIALIZER provider
         */
    }

}


