import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {getLocaleId} from '../services/i18n/i18n.function';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    private readonly localeId: string;

    constructor(private readonly translateService: TranslateService) {
        this.localeId = getLocaleId(translateService);
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req.clone({
            // server doesn't understand Accept-Language with region or with multiple languages (issue SMBP-4868),
            // so we need to truncate the locale to send only the language part
            headers: req.headers.set('Accept-Language',
                this.translateService?.currentLang?.substring(0, 2) || this.localeId.substring(0, 2))
        }));
    }
}
