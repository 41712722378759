<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable (click)="goToHome()"></izivia-back-button>

        <ion-title>{{'charge.plugTypeChoice.title' | translate}}</ion-title>
        <ion-buttons slot="end">
            <support-button [color]="'white'"></support-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card class="padding-16">
        <paynow-charge-header [newChargeContext]="newChargeContext"
                              [station]="station"
                              [step]="step"></paynow-charge-header>

    </ion-card>

    <plug-type-choice [(ngModel)]="plugTypeChoice"
                      [plugTypes]="plugTypeDtos"
                      [displayMode]="'list'"
                      *ngIf="station"></plug-type-choice>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar>
        <ion-button slot="start" color="secondary" expand="block" (click)="goToPreviousSlide()" class="previous">
            {{'common.previous' | translate}}
        </ion-button>
        <ion-button slot="end" color="secondary" expand="block" class="next"
                    (click)="goToNextSlide()" [disabled]="!canGoNext()">
            {{'common.next'| translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>
