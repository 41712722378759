import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FrontEndInfo, FrontEndHttpService, SupportContact, HOST_THIRD_PARTY_ID} from 'lib-front';
import {filter, first, map, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FrontEndService {
    readonly frontEndInfoSubject: BehaviorSubject<FrontEndInfo> = new BehaviorSubject(undefined);

    constructor(private readonly frontFrontHttpService: FrontEndHttpService,
        @Inject(HOST_THIRD_PARTY_ID) private thirdPartyId: string) {
    }

    loadFrontEndInfo(): Observable<FrontEndInfo> {
        return this.frontFrontHttpService.findFrontInfo(this.thirdPartyId)
            .pipe(tap(frontEndInfo => this.frontEndInfoSubject.next(frontEndInfo)));
    }

    get currentFrontEndInfo$(): Observable<FrontEndInfo> {
        return this.frontEndInfoSubject.asObservable()
            .pipe(first(frontEndInfo => frontEndInfo !== undefined));
    }
}
