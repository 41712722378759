import {Component} from '@angular/core';
import {EventCurrentService, GhostComponent, IziviaToastService, NewChargeContextService} from 'lib-mobile';
import {ActivatedRoute} from '@angular/router';
import {
    ChargeRecordDto,
    ChargeRecordStatus,
    EventDetailsType,
    EventTypes,
    FrontChargeRecordHttpService,
    FrontOtpHttpService,
    FrontReservationHttpService,
    HttpCodesUtil,
    httpRetry,
    IziviaLoggerService,
    OtpSessionDto,
    RemoteResponse,
    RemoteStatus,
    ReservationDetailDto
} from 'lib-front';
import {finalize, first, retryWhen, switchMap} from 'rxjs/operators';
import {NavController, ViewDidEnter, ViewWillEnter} from '@ionic/angular';
import {throwError} from 'rxjs';
import {FrontEndService} from '../../../services/frontEnd.service';

@Component({
    selector: 'event-details',
    templateUrl: './eventDetails.view.html',
    styleUrls: ['./eventDetails.view.scss']
})
export class EventDetailsView extends GhostComponent implements ViewWillEnter, ViewDidEnter {
    eventType: EventTypes;
    eventDetails: EventDetailsType;
    pollingTimerInSecond: number;
    private otpCode: string;

    constructor(private readonly chargeRecordHttpService: FrontChargeRecordHttpService,
        private readonly otpHttpService: FrontOtpHttpService,
        private readonly eventService: EventCurrentService,
        private readonly navController: NavController,
        private readonly route: ActivatedRoute,
        private readonly frontEndService: FrontEndService,
        private readonly reservationService: FrontReservationHttpService,
        private readonly loggerService: IziviaLoggerService,
        private readonly toastService: IziviaToastService,
        private readonly newChargeContextService: NewChargeContextService) {
        super();
    }

    ionViewWillEnter(): void {
        this.ghostMode = true;
    }

    ionViewDidEnter(): void {
        this.route.paramMap
            .pipe(
                first(),
                switchMap(params => {
                    this.eventType = params.get('type') as EventTypes;
                    const id: string = params.get('id');
                    this.otpCode = params.get('otpCode');

                    if (!this.eventType || !id || id === 'null') {
                        return throwError(`invalid event type: ${this.eventType} or id: ${id}`);
                    }

                    this.generateGhost();

                    switch (this.eventType) {
                        case 'charge':
                            return this.chargeRecordHttpService.byIdAndOtpCode(id, this.otpCode);
                        case 'otp':
                            return this.otpHttpService.getOtpSessionDto(id);
                        case 'reservation':
                            return this.reservationService.byId(id);
                        default:
                            return throwError(`Event type ${this.eventType} not handled (event id: ${id})`);
                    }
                }),
                retryWhen(httpRetry()),
                finalize(() => this.ghostMode = false)
            )
            .subscribe(
                eventDetails => {
                    if (this.eventType === 'charge' && (eventDetails as ChargeRecordDto).status === ChargeRecordStatus.VALORISED ) {
                        this.goToHome();
                        return undefined;
                    }
                    return this.eventDetails = eventDetails;
                },
                error => {
                    if (HttpCodesUtil.isForbidden(error)) {
                        this.loggerService.debug('[EventDetailsView] 403 Forbidden', error);
                    } else if (HttpCodesUtil.isNotFound(error)) {
                        this.loggerService.debug('[EventDetailsView] 404 Not Found', error);
                        this.toastService.showWarning('events.details.errors.notFound');
                    } else {
                        this.loggerService.warning('[EventDetailsView] ionViewDidEnter caught error:', error);
                    }
                    this.navController.navigateRoot('/home', {replaceUrl: true});
                }
            );
        this.frontEndService.currentFrontEndInfo$.subscribe(frontEndInfo => {
            if (frontEndInfo && frontEndInfo.mobileConfig && !!frontEndInfo.mobileConfig.pollingTimerInSecond) {
                this.pollingTimerInSecond = frontEndInfo.mobileConfig.pollingTimerInSecond;
            }
        });
    }

    public remoteDoneEvent(remoteResponse?: RemoteResponse) {
        if (this.eventType === 'charge') {
            this.eventService.deleteEvent((this.eventDetails as ChargeRecordDto).id)
                .subscribe(() => this.navController.navigateBack('/events/details/charge/stop', {replaceUrl: true}));
        } else if (this.eventType === 'otp' || this.eventType === 'reservation') {
            if (remoteResponse.status === RemoteStatus.DONE && remoteResponse?.chargeRef) {
                this.navController.navigateRoot(`/events/details/charge/${remoteResponse.chargeRef}/otp/${this.otpCode}`,
                    {replaceUrl: true, animationDirection: 'forward'});
            } else if (remoteResponse.status === RemoteStatus.TIMEOUT) {
                this.loggerService.debug('[handleRemoteResponse] TIMOUT', remoteResponse);
                const newChargeContext = this.createNewChargeContext();
                this.navController.navigateForward([`/app/new-charge/station/${this.eventDetails.infraChargeDto.station.id}/retry`], {
                    queryParams: newChargeContext.toQueryParam()
                });
            } else {
                this.loggerService.error('[remoteDoneEvent]: chargeRef is null', remoteResponse);
                const newChargeContext = this.createNewChargeContext();
                this.navController.navigateForward([`/app/new-charge/station/${this.eventDetails.infraChargeDto.station.id}/retry`], {
                    queryParams: newChargeContext.toQueryParam()
                });
            }
        }
    }

    private createNewChargeContext() {
        const newChargeContext = this.newChargeContextService.getOrCreateNewChargeContext();
        newChargeContext.chargePoint = this.eventDetails.infraChargeDto.chargePoint;
        newChargeContext.otpSession = {
            otpCode: this.otpCode,
            stationRef: this.eventDetails.infraChargeDto.station.id,
            chargePointRef: this.eventDetails.infraChargeDto.chargePoint.id,
            plugRef: this.eventDetails.infraChargeDto.plug.id
        };
        return newChargeContext;
    }

    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    public generateGhost(): void {
        switch (this.eventType) {
            case 'charge':
                this.eventDetails = {} as ChargeRecordDto;
                break;
            case 'otp':
                this.eventDetails = {} as OtpSessionDto;
                break;
            case 'reservation':
                this.eventDetails = {} as ReservationDetailDto;
                break;
        }
    }
}
