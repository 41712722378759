<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <izivia-back-button tappable defaultHref="/app/account"></izivia-back-button>
        <ion-title>{{'faq.title' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card>
        <ion-card-content>
            <faq-search (faqNodesChange)="treatFaqNodesSearched($event)"></faq-search>
        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="faqNodes?.length > 0 else noFaqNodes">
        <ion-card-content>
            <faq-list [faqNodes]="faqNodes"
                      [ghostMode]="ghostMode"
                      (faqNodeSelected)="goToFaqNode($event)"></faq-list>
        </ion-card-content>
    </ion-card>
</ion-content>

<ng-template #noFaqNodes>
    <picture-text-card [pictureUrl]="'assets/images/svg/empty-consumation.svg'"
                       [labelSubTitle]="'faq.search.noResult'">
    </picture-text-card>
</ng-template>
